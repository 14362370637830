.memberships {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: $font__museo--700;
  margin-top: 20px;

  @media only screen and (min-width: $screen-md-min) {
    justify-content: flex-end;
    align-items: flex-end;
  }

  .title {
    margin-bottom: 20px;
  }
}
.memberships__image img {
  max-width: 60px;
}

.memberships__image {
  margin-top: 20px;
}

.memberships__list {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media only screen and (min-width: $screen-md-min) {
    justify-content: flex-end;
  }

  li {
    margin: 5px;

    @media only screen and (min-width: $screen-md-min) {
      margin: 0 0 0 10px;
    }
  }
}
