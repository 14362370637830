.home__blog-showcase {
  position: relative;
  padding: 160px 0;

  background: rgb(209, 91, 165);
  background: linear-gradient(184deg, rgba(209, 91, 165, 1) 0%, rgba(255, 93, 83, 1) 100%);

  @media only screen and (max-width: $screen-md-min) {
    .blogs__grid li:not(.blogs__block--wide) {
      display: none;
    }
  }
}

.home__blog-showcase__title {
  font-family: $font__museo--1000;
  margin-bottom: 80px;
  text-align: center;
  color: $color_white;
  @include font-size(44);
}

// SVGS
.home__blog-showcase {
  overflow-x: hidden;
  // bring the container forward
  // so svgs don't cover it
  .container {
    position: relative;
    z-index: 1;
  }

  .svg-icon--yellow-line-lines {
    position: absolute;
    right: -20px;
    top: 50px;
    width: 60%;

    @media only screen and (min-width: $screen-md-min) {
      max-width: 800px;
      width: 100%;
    }
  }

  .svg-icon--blue-dots {
    position: absolute;
    left: -40px;
    bottom: 50px;
    width: 60%;

    @media only screen and (min-width: $screen-md-min) {
      width: auto;
    }
  }
}
