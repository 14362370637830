footer {
  background-color: #515150;
  color: #fff;
  position: relative;
  padding: 40px 0;

  @media only screen and (max-width: $screen-md-min) {
    overflow: hidden;
  }

  @media only screen and (min-width: $screen-md-min) {
    padding: 60px 0;
  }
}

footer .container {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.footer__right-section {
  width: 150px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media only screen and (min-width: $screen-md-min) {
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.footer__lower-section {
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: $screen-md-min) {
    .social-icons__list {
      order: 1;
      margin-top: 20px;
    }
    .memberships {
      order: 2;
    }
    .copyright {
      order: 3;
      margin-top: 20px;
    }
  }

  @media only screen and (min-width: $screen-md-min) {
    align-items: flex-end;
    flex-direction: row;

    .social-icons__list .social-icons__icon svg {
      width: auto;
      height: 35px;
      margin-right: 5px;
    }

    .social-icons__list,
    .memberships {
      width: 250px;
    }
    .social-icons__list {
      text-align: left;
    }
  }
}

footer {
  .logo,
  .navigation--footer {
    margin-bottom: 20px;

    @media only screen and (min-width: $screen-md-min) {
      margin-bottom: 40px;
    }
  }
}
footer {
  .social-icons__list {
    li:nth-child(1) {
      @media only screen and (min-width: $screen-md-min) {
        margin-left: 0px;
      }
    }
  }
}

footer .logo {
  cursor: pointer;
  width: 100px;
  margin: {
    right: auto;
    left: auto;
  }

  @media only screen and (min-width: $screen-md-min) {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-100%);
    z-index: 100;
  }
}

footer .navigation--footer {
  @media only screen and (min-width: $screen-md-min) {
    margin: auto;
    padding-bottom: 30px;
  }
}
footer .navigation--footer .navigation__list {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  margin-bottom: 20px;

  @media only screen and (min-width: $screen-md-min) {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  li {
    width: auto;
    // margin: 0 10px 15px 10px;

    @media only screen and (min-width: $screen-md-min) {
      margin-bottom: 0px;
    }
  }
}

footer .navigation--footer .navigation__link {
  color: #fff;
  padding: 0 10px;

  @include font-size(14);

  @media only screen and (min-width: $screen-md-min) and (max-width: $screen-lg-min) {
    @include font-size(10);
  }

  @media only screen and (min-width: $screen-md-min) {
    padding: 0 10px 0 0;
    margin-right: 50px;
  }
}
footer .copyright {
  font-family: $font__museo--700;
  flex-grow: 2;
}
footer .copyright .company-number {
  font-family: $font__museo--300;
}

footer .details {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: $font__museo--700;
  margin-top: 20px;

  @media only screen and (min-width: $screen-md-min) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

footer .details-address {
  @include font-size(14);
  font-family: $font__museo--300;
  text-align: center;
  line-height: 20px;
  h1 {
    font-family: $font__museo--700;
  }
  padding-bottom: 12px;
  @media only screen and (min-width: $screen-md-min) {
    text-align: left;
  }
}
footer .details-contact {
  @include font-size(14);
  font-family: $font__museo--300;
  text-align: center;
  line-height: 20px;
  padding-bottom: 12px;
  span {
    font-family: $font__museo--700;
  }
  @media only screen and (min-width: $screen-md-min) {
    text-align: left;
  }
}

// Footer SVG's
.footer__svg-container {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .svg-icon--yellow-line-lines {
    position: absolute;
    right: -20px;
    top: 60px;

    @media only screen and (max-width: $screen-md-min) {
      width: 60%;
    }

    @media only screen and (min-width: $screen-md-min) {
      max-width: 600px;
      top: 0;
    }

    .cls-1 {
      stroke: #ff91a3;
    }
  }

  .svg-icon--blue-dots {
    position: absolute;
    left: -20px;
    bottom: 0px;

    @media only screen and (max-width: $screen-md-min) {
      width: 80%;
      bottom: 50px;
    }
  }
}
