.timeline__now-section,
.timeline__then-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.timeline__now__title,
.timeline__then__title,
.timeline__date__title {
  background-color: $color_white;
  border: 2px dotted $color_dark-grey;
  border-radius: 25px;
  padding: 10px 25px;

  font-family: $font__museo--900-italic;
  @include font-size(20);

  margin-bottom: 30px;
}

.timeline__date__title {
  font-family: $font__museo--900;
}

.timeline__now__description,
.timeline__then__description {
  @include font-size(13);
  line-height: 1.6;

  position: relative;
  margin-bottom: 30px;
  padding: 5px 0;
}

.timeline__date__description {
  @include font-size(13);
  line-height: 1.6;

  position: relative;
  margin-bottom: 30px;
  padding: 5px 0;

  .content {
    position: relative;
  }

  @media only screen and (max-width: $screen-sm-min) {
    background-color: #ffffff;
  }
}

.timeline__dates-section {
  display: flex;
  margin-bottom: 40px;
  padding-top: 40px;

  flex-wrap: wrap;
  position: relative;

  &:before {
    content: '';
    height: 100%;
    width: 0;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    border-left: 2px dotted $color_dark-grey;

    @media only screen and (max-width: $screen-sm-min) {
      display: none;
    }
  }
}
.timeline__left,
.timeline__right {
  flex-basis: 50%;
  padding-top: 40px;
}

.timeline__date {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;

  flex-basis: 100%;

  @media only screen and (min-width: $screen-sm-min) {
    flex-basis: 50%;
  }

  &.left {
    .timeline__date__title {
      position: relative;

      &:before {
        content: '';
        height: 0;
        position: absolute;
        right: auto;
        top: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
        width: 85%;
        left: 50%;
        z-index: -1;
        border-top: 2px dotted $color_dark-grey;

        @media only screen and (min-width: $screen-sm-min) {
          position: absolute;
          right: -85%;
          left: auto;
          transform: translateY(-50%);
        }
      }
    }
  }

  &.right {
    .timeline__date__title {
      position: relative;
      background-color: $color_white;

      &:before {
        content: '';
        height: 0;
        width: 85%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
        border-top: 2px dotted $color_dark-grey;
        z-index: -1;

        @media only screen and (min-width: $screen-sm-min) {
          left: -85%;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  &:nth-child(even) {
    @media only screen and (min-width: $screen-sm-min) {
      padding-top: 30px;
    }
  }
}
