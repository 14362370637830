/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../scss/abstracts/breakpoints';
@import '../../../scss/abstracts/colors';
@import '../../../scss/abstracts/mixins';
@import '../../../scss/abstracts/typography';
@import '../../../scss/abstracts/animation';
@import '../../../scss/abstracts/variables';

.clients__grid {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  min-height: 200px;
}

.item {
  background: white;
  border-radius: 100%;
}
