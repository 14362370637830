.project__body {
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: $screen-md-min) {
    flex-direction: row;
  }
  min-height: 700px;

  .left,
  .right {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    width: 100%;
    @media only screen and (min-width: $screen-md-min) {
      width: 200px;
    }
  }

  .left {
    order: 1;
  }

  .right {
    order: 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 20px;

    button:first-child {
      @media only screen and (min-width: $screen-md-min) {
        margin-top: 40px;
      }
    }

    @media only screen and (min-width: $screen-md-min) {
      order: 3;
      justify-content: flex-start;
      margin-top: 0;
    }
  }

  .middle {
    width: 100%;
    order: 3;
    @media only screen and (min-width: $screen-md-min) {
      order: 2;
      width: calc(100% - 400px);
    }
  }
}

.project__body__description {
  padding: 40px 15px 0;
  line-height: calc(16px + 8px);

  &--divider {
    padding-bottom: 40px;
    margin-bottom: 40px;
    position: relative;

    @media only screen and (min-width: $screen-md-min) {
      padding-bottom: 80px;
      margin-bottom: 80px;
    }

    &:not(:last-child) {
      &:after {
        content: '';
        background: rgb(71, 205, 199);
        background: -moz-linear-gradient(222deg, rgba(71, 205, 199, 1) 0%, rgba(255, 145, 163, 1) 100%);
        background: -webkit-linear-gradient(222deg, rgba(71, 205, 199, 1) 0%, rgba(255, 145, 163, 1) 100%);
        background: linear-gradient(222deg, rgba(71, 205, 199, 1) 0%, rgba(255, 145, 163, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#47cdc7",endColorstr="#ff91a3",GradientType=1);
        height: 5px;
        width: 80%;
        left: 50%;
        bottom: 0;
        position: absolute;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }

  // img {
  //   margin-bottom: 20px;
  //
  //   @media only screen and (min-width: $screen-md-min) {
  //     &.align-left {
  //       position: relative;
  //       margin-left: -200px;
  //       width: calc(100% + 200px);
  //     }
  //
  //     &.align-right {
  //       position: relative;
  //       margin-right: -200px;
  //       width: calc(100% + 200px);
  //     }
  //   }
  // }
  //
  // a {
  //   color: #2ca1c0;
  //
  //   &:hover {
  //     color: #515150;
  //   }
  // }
  //
  // p {
  //   @include font-size(16);
  //   line-height: calc(16px + 8px);
  //   margin-bottom: 20px;
  //   padding: {
  //     right: 15px;
  //     left: 15px;
  //   }
  //
  //   @media only screen and (min-width: $screen-md-min) {
  //     margin-bottom: 40px;
  //     padding: {
  //       right: 0;
  //       left: 0;
  //     }
  //   }
  //
  //   &:first-child {
  //     font-family: $font__museo--700;
  //
  //     @include font-size(18);
  //     line-height: calc(18px + 10px);
  //
  //     @media only screen and (min-width: $screen-md-min) {
  //       @include font-size(22);
  //       line-height: calc(22px + 10px);
  //     }
  //
  //     @media only screen and (min-width: $screen-lg-min) {
  //       @include font-size(26);
  //       line-height: calc(26px + 10px);
  //     }
  //   }
  // }
  //
  // blockquote {
  //   background-color: rgba(44, 161, 192, 0.9);
  //   p,
  //   p:first-child {
  //     color: $color_yellow;
  //     padding: 25px;
  //     font-family: $font__museo--700;
  //
  //     @include font-size(18);
  //
  //     @media only screen and (min-width: $screen-md-min) {
  //       padding: 10px;
  //       @include font-size(22);
  //       line-height: 33px;
  //     }
  //   }
  // }
}

.project__body__logo {
  background-color: #515150;
  border-radius: 50%;
  margin-top: 20px;

  width: 100px;
  height: 100px;
  padding: 20px;

  @media only screen and (min-width: $screen-md-min) {
    margin-top: 40px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
}

.project__body__external-link {
  background-color: transparent;
  border: 2px solid $color_dark-grey;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin: {
    top: 0px;
    right: 15px;
    bottom: 10px;
    left: 15px;
  }

  @media only screen and (min-width: $screen-md-min) {
    margin-right: 0;
    margin-left: 0;
  }

  @include transition(border-color);

  a {
    color: $color_dark-grey;
    font-family: $font__museo--900;
    text-decoration: none;
    @include font-size(14);
    @include transition(color);
  }

  &:hover {
    border-color: $color_yellow;

    a {
      color: $color_yellow;
    }
  }
}
