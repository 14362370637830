.image-collages {
  position: relative;
}
.image-collages__show-more {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  margin: {
    top: 20px;
    right: auto;
    left: auto;
  }
  font-family: $font__museo--900;
  @include font-size(18);
  color: #fff;

  @media only screen and (min-width: $screen-md-min) {
    margin-top: 40px;
  }

  @include transition(color, border-color);

  &:hover {
    border-color: $color_yellow;
    color: $color_yellow;

    .svg-icon--arrow-down {
      fill: $color_yellow;
    }
  }

  .svg-icon--arrow-down {
    @include transition(fill);
    margin-left: 5px;
    height: 16px;
  }
}
