$font_path: '../fonts';

// italic
@font-face {
  font-family: 'museo_slab900_italic';
  src: url('#{$font_path}/museo_slab_900italic/museo_slab_900italic-webfont.woff2') format('woff2'), url('#{$font_path}/museo_slab_900italic/museo_slab_900italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'museo_slab700_italic';
  src: url('#{$font_path}/museo_slab_700italic/museo_slab_700italic-webfont.woff2') format('woff2'), url('#{$font_path}/museo_slab_700italic/museo_slab_700italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'museo_slab1000_italic';
  src: url('#{$font_path}/museo_slab_1000italic/museo_slab_1000italic-webfont.woff2') format('woff2'), url('#{$font_path}/museo_slab_1000italic/museo_slab_1000italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// standard
@font-face {
  font-family: 'museo_slab1000';
  src: url('#{$font_path}/museo_slab_1000/museo_slab_0-webfont.woff2') format('woff2'), url('#{$font_path}/museo_slab_1000/museo_slab_0-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'museo_slab300';
  src: url('#{$font_path}/museo_slab_300/museo_slab_1-webfont.woff2') format('woff2'), url('#{$font_path}/museo_slab_300/museo_slab_1-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'museo_slab700';
  src: url('#{$font_path}/museo_slab_700/museo_slab_3-webfont.woff2') format('woff2'), url('#{$font_path}/museo_slab_700/museo_slab_3-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'museo_slab500';
  src: url('#{$font_path}/museo_slab_500/museo_slab_2-webfont.woff2') format('woff2'), url('#{$font_path}/museo_slab_500/museo_slab_2-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'museo_slab900';
  src: url('#{$font_path}/museo_slab_900/museo_slab_4-webfont.woff2') format('woff2'), url('#{$font_path}/museo_slab_900/museo_slab_4-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
