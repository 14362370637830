/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../scss/abstracts/breakpoints';
@import '../../../scss/abstracts/colors';
@import '../../../scss/abstracts/mixins';
@import '../../../scss/abstracts/typography';
@import '../../../scss/abstracts/animation';
@import '../../../scss/abstracts/variables';

.clients__header {
  background: rgb(71, 205, 199);
  background: -moz-linear-gradient(222deg, rgba(255, 161, 123, 1) 0%, rgba(255, 195, 43, 1) 100%);
  background: -webkit-linear-gradient(222deg, rgba(255, 161, 123, 1) 0%, rgba(255, 195, 43, 1) 100%);
  background: linear-gradient(222deg, rgba(255, 161, 123, 1) 0%, rgba(255, 195, 43, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#47cdc7",endColorstr="#ff91a3",GradientType=1);

  padding: 40px 0;
  overflow: hidden;

  @media only screen and (min-width: $screen-md-min) {
    padding: 80px 0;
  }

  .container {
    position: relative;
  }
}

.clients__header .clients__title {
  position: relative;

  text-align: center;
  color: #fff;
  font-family: $font__museo__1000;
  margin: 20px 0 40px;

  @include font-size(45);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(60);
  }

  @media only screen and (min-width: $screen-lg-min) {
    @include font-size(95);

    padding: {
      right: 120px;
      left: 120px;
    }
  }
}

.clients__header .clients__description {
  position: relative;

  text-align: center;

  color: #fff;
  font-family: $font__museo--300;
  line-height: 1.4;

  padding: {
    right: 15px;
    left: 15px;
  }

  width: 100%;
  max-width: 780px;
  margin: auto;

  @include font-size(16);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(24);
  }
}

// SVG's
.clients__header .svg-icon--lines-circle {
  position: absolute;
  top: -150px;
  left: -170px;

  width: 180px;

  display: none;
  @media only screen and (min-width: $screen-sm-min) {
    display: block;
  }

  @media only screen and (min-width: $screen-lg-min) {
    left: -50px;
  }
}
.clients__header .svg-icon--blue-lines-triangle {
  position: absolute;
  bottom: -200px;
  left: -140px;
  width: 200px;
  z-index: 10;

  display: none;
  @media only screen and (min-width: $screen-sm-min) {
    display: block;
  }
  @media only screen and (min-width: $screen-lg-min) {
    left: 40px;
  }
}
.clients__header .svg-icon--red-lines-rectangle {
  position: absolute;
  top: -50px;
  right: -140px;

  transform: translateY(-50%);
  transform: scaleX(-1);
  width: 300px;

  display: none;
  @media only screen and (min-width: $screen-sm-min) {
    display: block;
  }

  @media only screen and (min-width: $screen-lg-min) {
    right: 0;
  }
}
