.privacy-policy .container {
  background-color: #fff;
  padding-top: 40px;
  padding-bottom: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
}

.privacy-policy h1 {
  text-align: center;
  color: $color_yellow;
  font-family: $font__museo--1000;
  margin-bottom: 20px;
  @include font-size(35);

  @media only screen and (min-width: $screen-md-min) {
    margin-bottom: 40px;
    @include font-size(60);
  }
}

.privacy-policy h2 {
  margin-bottom: 20px;

  @include font-size(26);
  font-family: $font__museo--900;

  @media only screen and (min-width: $screen-md-min) {
    margin-bottom: 40px;

    @include font-size(30);
  }
}
