/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../../scss/abstracts/breakpoints';
@import '../../../../scss/abstracts/colors';
@import '../../../../scss/abstracts/mixins';
@import '../../../../scss/abstracts/typography';
@import '../../../../scss/abstracts/animation';
@import '../../../../scss/abstracts/variables';

// html.mobile,
// html.tablet {
//   .home-header {
//     height: 95vh;
//   }
// }
.home-header {
  background-color: #000;
  position: relative;
  overflow: hidden;
  margin-top: 70px;
  padding-top: 56.25%;

  @media only screen and (min-width: $screen-md-min) {
    margin-top: 0;
  }

  @media only screen and (min-width: $screen-lg-min) {
    height: 100vh;
    padding-top: 0;
  }
}
.home-header video {
  left: 0;
  top: 0;
  width: 100%;
  position: absolute;

  @media only screen and (min-width: $screen-lg-min) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: 100vh;
  }
}

.home-header__middle-region {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;

  .container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;

    @media only screen and (min-width: $screen-md-min) {
      flex-direction: row;
    }
  }
}

.home-header__middle-region .jollywise-logo {
  display: none;
  align-items: center;

  @media only screen and (min-width: $screen-md-min) {
    width: 30%;
    display: flex;
    max-width: 400px;
  }
}

.home-header__bottom-region {
  position: absolute;
  bottom: 20px;
  width: 100%;

  .container {
    display: flex;
    align-items: center;
  }
}

.home-header__arrow {
  display: none;

  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: -60px;
  @media only screen and (min-width: $screen-lg-min) {
    animation: bounce 2s infinite 0s;
    top: 0;
    display: block;
  }

  button {
    background: transparent;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    border: 3px solid #fff;
    @include transition(border-color);

    @media (hover: hover) {
      &:hover {
        border-color: $color_yellow;

        .svg-icon--arrow-down {
          fill: $color_yellow;
        }
      }
    }
  }
  .svg-icon--arrow-down {
    height: 21px;
    width: 21px;

    @include transition(fill);
  }
}
