.categories__block {
  display: flex;
  // justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  transform-origin: center;
  @include transition(transform 0.5s);

  flex-basis: 100%;
  padding-top: 76.1%;

  @media only screen and (min-width: $screen-sm-min) {
    flex-basis: 50%;
    padding-top: 38%;
  }

  @media only screen and (min-width: $screen-md-min) {
    flex-basis: 20%;
    padding-top: 15.3%;
  }

  &:hover {
    .categories__block__background-image {
      opacity: 0;
    }
  }
}

.categories__block__background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  opacity: 1;
  @include transition(transform, opacity);
  z-index: 10;

  @media only screen and (max-width: 768px) {
    opacity: 0;
  }
}

.categories__block--show {
  transform: scale(1);
}

.categories__block__video {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.categories__block__inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);

  &--loading::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.1) 100%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

.categories__block__logo {
  max-width: 60px;
  transform-origin: center;
  transform: scale(1);
  @include transition(transform);
}
