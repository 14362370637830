/*
This file is used to import ONLY the -dir.scss files.
*/

// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math

//Import base abstracts files
@import 'abstracts/breakpoints';
@import 'abstracts/colors';
@import 'abstracts/mixins';
@import 'abstracts/typography';
@import 'abstracts/animation';
@import 'abstracts/variables';

/* Abstract file import */
// This is included in the webpack config
// to be globally available
// @import 'abstracts/__abstracts-dir';

/* Vendor file import */
@import 'vendor/__vendor-dir';

/* Base file import */
@import 'base/__base-dir';

/* Components file import */
@import 'components/__components-dir';

/* Layouts file import */
@import 'layouts/__layouts-dir';

// hacky background image pathing
.masonry-brick:after {
  @include backgroundimage('/base/testimonial-bottom.png');
  background-repeat: no-repeat;
}
