/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../scss/abstracts/breakpoints';
@import '../../../scss/abstracts/colors';
@import '../../../scss/abstracts/mixins';
@import '../../../scss/abstracts/typography';
@import '../../../scss/abstracts/animation';
@import '../../../scss/abstracts/variables';

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
  background-color: #fff;
  height: 70px;
  @include transition(background-color 0.5s);

  @supports (backdrop-filter: saturate(180%) blur(20px)) {
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(255, 255, 255, 0.75);

    @include transition(background-color 0.5s, backdrop-filter 0.5s);
  }

  .container {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
  }

  .logo {
    width: 50px;
    @include transition(width, transform 0.3s ease-in-out 0.3s);
    transform-origin: center;
    width: 50px;
    transform: scale(1);
    margin-right: 10px;
    cursor: pointer;

    a {
      display: block;
    }
  }

  .social-icons__list {
    width: 95px;
    opacity: 1;
    margin-left: 10px;
    display: none;
    justify-content: flex-end;
    @include transition(width, opacity 1s ease-in-out 0.3s);

    @media only screen and (min-width: $screen-md-min) {
      display: flex;
    }

    svg {
      width: 25px;
      height: 25px;

      fill: $color_dark-grey;
    }
  }
}

@media only screen and (min-width: $screen-md-min) {
  .header--home--is-top {
    background-color: transparent;
    @supports (backdrop-filter: none) {
      backdrop-filter: none;
    }

    // .logo {
    //   width: 0;
    //   transform: scale(0);
    //   @include transition(transform, width 0.3s ease-in-out 0.3s);
    // }

    .social-icons__list {
      svg {
        fill: $color_white;
      }
    }

    .navigation__link {
      color: #fff;

      &:hover {
        color: $color_dark-grey;
      }
    }
  }
}
