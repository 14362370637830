/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../../scss/abstracts/breakpoints';
@import '../../../../scss/abstracts/colors';
@import '../../../../scss/abstracts/mixins';
@import '../../../../scss/abstracts/typography';
@import '../../../../scss/abstracts/animation';
@import '../../../../scss/abstracts/variables';

.categories__header {
  background: rgb(71, 205, 199);
  background: -moz-linear-gradient(222deg, rgba(71, 167, 206, 1) 0%, rgba(71, 205, 199, 1) 100%);
  background: -webkit-linear-gradient(222deg, rgba(71, 167, 206, 1) 0%, rgba(71, 205, 199, 1) 100%);
  background: linear-gradient(222deg, rgba(71, 167, 206, 1) 0%, rgba(71, 205, 199, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#47cdc7",endColorstr="#ff91a3",GradientType=1);

  padding: 40px 0;
  overflow: hidden;

  @media only screen and (min-width: $screen-md-min) {
    padding: 80px 0;
  }

  .container {
    position: relative;
  }
}

.categories__header .categories__title {
  position: relative;

  text-align: center;
  color: #fff;
  font-family: $font__museo__1000;
  margin: 20px 0 40px;

  @include font-size(45);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(60);
  }

  @media only screen and (min-width: $screen-lg-min) {
    @include font-size(95);

    padding: {
      right: 120px;
      left: 120px;
    }
  }
}

.categories__header .categories__description {
  position: relative;

  text-align: center;

  color: #fff;
  font-family: $font__museo--300;
  line-height: 1.4;

  padding: {
    right: 15px;
    left: 15px;
  }

  width: 100%;
  max-width: 780px;
  margin: auto;

  @include font-size(16);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(24);
  }
}

// SVG's
.categories__header .svg-icon--red-lines {
  position: absolute;
  top: -150px;
  width: 400px;
  left: -200px;

  display: none;
  @media only screen and (min-width: $screen-sm-min) {
    display: block;
  }
}
.categories__header .svg-icon--swirls {
  position: absolute;
  top: -150px;
  width: 400px;
  right: -100px;
  transform: scaleX(-1);
  display: none;

  @media only screen and (min-width: $screen-sm-min) {
    display: block;
  }
}

.svg-icon--heart {
  fill: none;
  stroke: #eb685b;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 10px;
}
