.blog__related_block {
  flex-basis: 100%;
  opacity: 0;
  background-color: #fff;
  padding-bottom: 40px;

  @media only screen and (min-width: $screen-md-min) {
    flex-basis: 50%;
  }
}

.blog__related_block__image {
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;

  img {
    top: 50%;
    left: 50%;
    position: absolute;
    width: 120%;
    transform: translate(-50%, -50%);
  }
}

.blog__related_block {
  figcaption {
    position: absolute;
    bottom: 30px;
    max-width: 90%;
    line-height: 1;
    padding: 5px 20px;

    font-family: $font__museo__900;
    @include font-size(22);
  }

  &--wide {
    @media only screen and (min-width: $screen-md-min) {
      .blog__related_block__body {
        width: 80%;
        margin: 30px auto;
      }

      figcaption {
        bottom: 60px;
        padding-left: 80px;

        font-family: $font__museo__1000;
        @include font-size(36);
      }
    }
  }

  &:nth-child(5n + 1) {
    figcaption {
      color: $color_yellow;
      background-color: rgba(44, 161, 192, 0.9);
    }
  }
  &:nth-child(5n + 2) {
    figcaption {
      color: $color_dark-grey;
      background-color: rgba(252, 201, 33, 0.9);
    }
  }
  &:nth-child(5n + 3) {
    figcaption {
      color: #fff;
      background-color: rgba(202, 91, 89, 0.9);
    }
  }
  &:nth-child(5n + 4) {
    figcaption {
      color: $color_yellow;
      background-color: rgba(205, 119, 161, 0.9);
    }
  }
  &:nth-child(5n + 5) {
    figcaption {
      color: #fff;
      background-color: rgba(71, 156, 195, 0.9);
    }
  }
}

.blog__related_block__body {
  display: flex;
  margin: 25px;
}

.blog__related_block__intro {
  line-height: 1.4;
  //
  // height: 68px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

.blog__related_block__date {
  font-family: $font__museo--900;
  @include font-size(14);
  margin-bottom: 10px;
}

.blog__related_block__left-section {
  flex-basis: calc(100% - 15px);
  margin-right: 15px;
}

.blog__related_block__right-section {
  flex-basis: 130px;
  margin-left: auto;
}

.blog__related_block__link {
  border: 2px solid $color_dark-grey;
  border-radius: 25px;
  color: $color_dark-grey;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  font-family: $font__museo--900;

  @include font-size(14);
  @include transition(border-color, color);

  &:hover {
    border-color: $color_yellow;
    color: $color_yellow;
  }
}
