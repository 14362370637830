.employee {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (min-width: $screen-md-min) {
    padding-left: 100px;
    align-items: flex-start;
  }
}
