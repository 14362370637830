/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../../scss/abstracts/breakpoints';
@import '../../../../scss/abstracts/colors';
@import '../../../../scss/abstracts/mixins';
@import '../../../../scss/abstracts/typography';
@import '../../../../scss/abstracts/animation';
@import '../../../../scss/abstracts/variables';

.home-showcase_content {
  position: relative;
  overflow: hidden;
}

.home-showcase_content figcaption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .container {
    height: 100%;
  }
}

.home-showcase_content__details {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  position: relative;
  width: 65%;
  margin-left: 35%;
}

.home-showcase_content__title {
  background-color: rgba(44, 161, 192, 0.9);
  color: $color_yellow;
  font-family: $font__museo--1000;

  padding: 10px;
  margin-bottom: 30px;
  line-height: 1.2;
  @include font-size(18);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(50);
    padding: 15px;
  }
}

.home-showcase_content__details a {
  display: inline;
  background-color: rgba(44, 161, 192, 0.9);
  color: $color_yellow;
  font-family: $font__museo--900;
  padding: 10px;
  line-height: 1;

  @include font-size(14);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(20);
    padding: 15px;
  }
}
