// Setup colours for across the site
$color_white: #ffffff;
$color_yellow: #ffd201;
$color_dark-grey: #515150;
$color_pink: #ffd3da;
$color_red: #ec685b;
$color_pale_red: #f892a4;
$color_blue: #4ecdc6;
$color_cyan: #47cdc7;

$color_testimonials_red: $color_pale_red;
$color_testimonials_yelow: $color_yellow;
$color_testimonials_blue: $color_blue;


$color_process1: #ffd107;
$color_process2: #c062a2;
$color_process3: #5cbebd;
$color_process4: #f08ea2;
$color_process5: #ea685b;
$color_process6: #6ab063;