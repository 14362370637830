.category__grid {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  min-height: 200px;

  justify-content: center;
  @include transition(width);
  width: 100%;

  &--3 {
    @media only screen and (min-width: $screen-xl-min) {
      width: 75%;
      margin: 0 auto;
    }
  }
}

.item {
  background: white;
  border-radius: 100%;
}

.category__grid__show-more {
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin: {
    bottom: 40px;
    top: 20px;
    right: auto;
    left: auto;
  }
  font-family: $font__museo--900;
  @include font-size(18);
  color: #fff;

  @media only screen and (min-width: $screen-md-min) {
    margin-top: 40px;
  }

  @include transition(color, border-color);

  &:hover {
    border-color: $color_yellow;
    color: $color_yellow;

    .svg-icon--arrow-down {
      fill: $color_yellow;
    }
  }

  .svg-icon--arrow-down {
    @include transition(fill);
    margin-left: 5px;
    height: 16px;
  }
}
