/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../../scss/abstracts/breakpoints';
@import '../../../../scss/abstracts/colors';
@import '../../../../scss/abstracts/mixins';
@import '../../../../scss/abstracts/typography';
@import '../../../../scss/abstracts/animation';
@import '../../../../scss/abstracts/variables';

.experts {
  background: rgb(71, 205, 199);
  background: -moz-linear-gradient(222deg, rgba(71, 167, 206, 1) 0%, rgba(71, 205, 199, 1) 100%);
  background: -webkit-linear-gradient(222deg, rgba(71, 167, 206, 1) 0%, rgba(71, 205, 199, 1) 100%);
  background: linear-gradient(222deg, rgba(71, 167, 206, 1) 0%, rgba(71, 205, 199, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#47cdc7",endColorstr="#ff91a3",GradientType=1);
  position: relative;

  padding: 40px 0;
  overflow: hidden;

  .container {
    position: relative;
    max-width: 1400px;
  }
}

$color_1: #ff8699;
$color_2: #32c6bf;
$color_3: #ed5d52;
$color_4: #be5697;
$color_5: #5ba85d;
$color_6: #ffcd2e;

.experts__title {
  position: relative;

  text-align: center;
  color: $color_yellow;
  font-family: $font__museo__1000;
  margin: 20px 0;

  @include font-size(35);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(50);
  }

  @media only screen and (min-width: $screen-lg-min) {
    @include font-size(70);
  }
}

.experts__description {
  position: relative;

  text-align: left;

  color: #fff;
  font-family: $font__museo--300;
  line-height: 1.4;

  padding: 20px 15px;

  width: 100%;
  max-width: 780px;
  margin: auto;
  margin-bottom: 20px;

  @include font-size(16);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(18);
  }
}

.experts__grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.experts__block {
  background-color: #fff;
  padding: 30px;
  flex-basis: calc(100% - 30px);
  margin: 0 15px 10px;
  color: $color_dark-grey;
  border-radius: 5px;

  margin-bottom: 30px;

  @media only screen and (min-width: $screen-md-min) {
    flex-basis: calc(50% - 30px);
    min-height: 240px;
  }

  @media only screen and (min-width: $screen-lg-min) {
    flex-basis: calc(33.3% - 30px);
    padding: 40px;
  }

  // rotate the block
  transform: rotate(-1deg);
  .experts__block__container {
    transform: rotate(1deg);
  }

  &:nth-child(odd) {
    transform: rotate(1deg);
    .experts__block__container {
      transform: rotate(-1deg);
    }
  }

  &:nth-child(6n + 1) {
    .experts__block__title {
      color: $color_1;
    }
  }
  &:nth-child(6n + 2) {
    .experts__block__title {
      color: $color_2;
    }
  }
  &:nth-child(6n + 3) {
    .experts__block__title {
      color: $color_3;
    }
  }
  &:nth-child(6n + 4) {
    .experts__block__title {
      color: $color_4;
    }
  }
  &:nth-child(6n + 5) {
    .experts__block__title {
      color: $color_5;
    }
  }
  &:nth-child(6n + 6) {
    .experts__block__title {
      color: $color_6;
    }
  }
}

.experts__block__title {
  text-align: center;
  margin-bottom: 20px;
  font-family: $font__museo__1000;

  @include font-size(28);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(26);
  }

  @media only screen and (min-width: $screen-xl-min) {
    @include font-size(30);
  }
}

.experts__block__description {
  line-height: 1.3;
}

// SVG's
.experts .svg-icon--liney-lines {
  position: absolute;
  left: -140px;
  top: 200px;
  width: 500px;

  display: none;
  @media only screen and (min-width: $screen-sm-min) {
    display: block;
  }
}
.experts .svg-icon--swirls {
  position: absolute;
  bottom: -160px;
  width: 400px;
  right: -100px;
  transform: scaleX(-1);
  display: none;

  @media only screen and (min-width: $screen-sm-min) {
    display: block;
  }
}

.svg-icon--heart {
  fill: none;
  stroke: #eb685b;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 10px;
}
