.creative-services__cta-block {
  padding-top: 22%;
  position: relative;
  width: 100%;

  @media only screen and (min-width: $screen-md-min) {
    width: calc(100% / 5);
  }

  &:hover {
    .title {
      transform: scale(0.8);
    }
  }
}

.creative-services__cta-block .title {
  color: $color_white;
  @include transition(transform);
  @include font-size(18);
  font-family: $font__museo--900;
  text-align: center;

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(22);
  }
}

.creative-services__cta-block__clickable-area {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 11;
  padding: 15px;
  cursor: pointer;
}

.creative-services__cta-block__character_development {
  background: rgb(71, 167, 206);
  background: linear-gradient(216deg, rgba(71, 167, 206, 1) 0%, rgba(71, 205, 199, 1) 100%);
}
.creative-services__cta-block__ui_ux {
  background: rgb(195, 98, 163);
  background: linear-gradient(216deg, rgba(195, 98, 163, 1) 0%, rgba(236, 104, 91, 1) 100%);
}
.creative-services__cta-block__branding {
  background: rgb(236, 105, 90);
  background: linear-gradient(216deg, rgba(236, 105, 90, 1) 0%, rgba(255, 210, 8, 1) 100%);
}
.creative-services__cta-block__short_form_video {
  background: rgb(72, 167, 202);
  background: linear-gradient(216deg, rgba(72, 167, 202, 1) 0%, rgba(104, 177, 104, 1) 100%);
}
.creative-services__cta-block__banners {
  background: rgb(71, 204, 199);
  background: linear-gradient(216deg, rgba(71, 204, 199, 1) 0%, rgba(246, 147, 165, 1) 100%);
}
