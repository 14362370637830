.social__block {
  position: relative;
  overflow: hidden;

  transform-origin: center;
  @include transition(transform 0.5s);

  flex-basis: 100%;
  padding-top: 76.1%;

  @media only screen and (min-width: $screen-sm-min) {
    flex-basis: 50%;
    padding-top: 38%;
  }

  @media only screen and (min-width: $screen-md-min) {
    flex-basis: 20%;
    padding-top: 15.3%;
  }

  &:hover {
    .social__block__background-image {
      transform: scale(1.2);
    }

    .social__block__title {
      transform: scale(0.9);
    }
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.social__block--show {
  transform: scale(1);
}

.social__block__background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  opacity: 1;

  transform-origin: center;
  transform: scale(1);
  @include transition(transform, opacity);
}

.social__block__inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.social__block__title {
  transform-origin: center;
  transform: scale(1);
  @include transition(transform);

  color: #fff;
  font-family: $font__museo__900;
  @include font-size(18);
  text-align: center;

  @media only screen and (min-width: 400px) {
    @include font-size(30);
  }

  @media only screen and (min-width: $screen-sm-min) {
    @include font-size(26);
  }

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(16);
  }

  @media only screen and (min-width: $screen-lg-min) {
    @include font-size(20);
  }

  @media only screen and (min-width: $screen-xl-min) {
    @include font-size(26);
  }
}
