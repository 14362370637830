/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../scss/abstracts/breakpoints';
@import '../../../scss/abstracts/colors';
@import '../../../scss/abstracts/mixins';
@import '../../../scss/abstracts/typography';
@import '../../../scss/abstracts/animation';
@import '../../../scss/abstracts/variables';

.clients__block {
  position: relative;
  overflow: hidden;

  transform-origin: center;
  @include transition(transform 0.5s);

  flex-basis: 50%;
  padding-top: 38%;

  @media only screen and (min-width: $screen-md-min) {
    flex-basis: 25%;
    padding-top: 19%;
  }

  &:hover {
    .clients__block__background-image {
      transform: scale(1.2);
    }

    .clients__block__logo {
      transform: scale(0.9);
    }
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.clients__block--show {
  transform: scale(1);
}

.clients__block__background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  opacity: 1;

  transform-origin: center;
  transform: scale(1);
  @include transition(transform, opacity);
}

.clients__block__inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.clients__block__logo {
  max-height: 70px;
  max-width: 140px;
  transform-origin: center;
  transform: scale(1);
  @include transition(transform);
}
