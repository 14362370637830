/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../../scss/abstracts/breakpoints';
@import '../../../../scss/abstracts/colors';
@import '../../../../scss/abstracts/mixins';
@import '../../../../scss/abstracts/typography';
@import '../../../../scss/abstracts/animation';
@import '../../../../scss/abstracts/variables';

.categories__process {
  background: rgb(71, 205, 199);
  background: -moz-linear-gradient(222deg, rgba(195, 98, 163, 1) 0%, rgba(236, 104, 91, 1) 100%);
  background: -webkit-linear-gradient(222deg, rgba(195, 98, 163, 1) 0%, rgba(236, 104, 91, 1) 100%);
  background: linear-gradient(222deg, rgba(195, 98, 163, 1) 0%, rgba(236, 104, 91, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#47cdc7",endColorstr="#ff91a3",GradientType=1);

  padding: 40px 0;
  overflow: hidden;

  @media only screen and (min-width: $screen-md-min) {
    padding: 80px 0;
  }

  .container {
    position: relative;
  }
}

.categories__process .categories__title {
  position: relative;

  text-align: center;
  color: #fff;
  font-family: $font__museo__1000;
  margin: 20px 0 40px;

  @include font-size(45);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(60);
  }

  @media only screen and (min-width: $screen-lg-min) {
    @include font-size(95);

    padding: {
      right: 120px;
      left: 120px;
    }
  }

  svg {
    display: inline-block;
    vertical-align: middle;
  }
}

.categories__process .categories__description {
  position: relative;

  text-align: center;

  color: #fff;
  font-family: $font__museo--300;
  line-height: 1.4;

  padding: {
    right: 15px;
    left: 15px;
    top: 40px;
    bottom: 80px;
  }

  width: 100%;
  max-width: 780px;
  margin: auto;

  @include font-size(16);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(24);
  }
}

.categories__process__container {
  background-color: #fff;
  transform: rotate(-0.5deg);

  margin-bottom: -30px;

  @media only screen and (min-width: $screen-md-min) {
    transform: rotate(-1deg);
    padding-right: 80px;
    padding-left: 80px;
  }

  .container {
    padding: 0;
    transform: rotate(0.5deg);

    @media only screen and (min-width: $screen-md-min) {
      transform: rotate(1deg);
    }
  }
}

.categories__process_grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 40px;
  padding-bottom: 40px;

  li:nth-child(1) .categories__process_block_title {
    color: $color_process1;
  }
  li:nth-child(2) .categories__process_block_title {
    color: $color_process2;
  }
  li:nth-child(3) .categories__process_block_title {
    color: $color_process3;
  }
  li:nth-child(4) .categories__process_block_title {
    color: $color_process4;
  }
  li:nth-child(5) .categories__process_block_title {
    color: $color_process5;
  }
  li:nth-child(6) .categories__process_block_title {
    color: $color_process6;
  }
}

.categories__process_block {
  flex-basis: calc(100% - 20px);
  padding-bottom: 5%;
  margin: 0 10px 10px;
  color: $color_dark-grey;

  @media only screen and (min-width: $screen-lg-min) {
    flex-basis: calc(50% - 20px);
  }
}

.categories__process_block {
  position: relative;
}

.categories__process_block__details {
  z-index: 2;
  width: 100%;
  top: 25px;
  margin: 0 auto;

  @media only screen and (min-width: $screen-md-min) {
    width: 80%;
  }

  svg {
    display: block;
    margin: 0 auto 10px;
  }
}
.categories__process_block_title {
  font-family: $font__museo--900;
  @include font-size(32);
  text-align: center;
  padding-bottom: 20px;
}

.categories__process_block_list {
  font-family: $font__museo--300;
  text-align: left;
  list-style: disc outside;
  line-height: 1.8;

  @include font-size(16);
  @media only screen and (min-width: $screen-md-min) {
    @include font-size(18);
  }
}

// SVG's
.categories__process .svg-icon--swirls {
  position: absolute;
  top: 500px;
  width: 400px;
  left: -280px;
  z-index: 10;

  @media only screen and (min-width: $screen-sm-min) {
    display: block;
  }
}
.categories__process .svg-icon--dots {
  position: absolute;
  width: 250px;
  left: -300px;
  top: 80px;
  z-index: 10;

  @media only screen and (min-width: $screen-sm-min) {
    width: 350px;
  }

  @media only screen and (min-width: $screen-md-min) {
    width: 500px;
  }
}

.svg-icon--hand-cls-1,
.svg-icon--hand-cls-2 {
  fill: none;
  stroke: #ffd206;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
.svg-icon--hand-cls-1 {
  stroke-width: 10px;
}
.svg-icon--hand-cls-2 {
  stroke-width: 8.1px;
}
