$font__museo--300: 'museo_slab300';
$font__museo--500: 'museo_slab500';
$font__museo--700: 'museo_slab700';
$font__museo--900: 'museo_slab900';
$font__museo--1000: 'museo_slab1000';

$font__museo--700-italic: 'museo_slab700_italic';
$font__museo--900-italic: 'museo_slab900_italic';
$font__museo--1000-italic: 'museo_slab1000_italic';

// Default Font Size
// value repersented in pixels
$default-text-size: 16;

// Heading Font Sizes
$font-size-h1: '50px';
$font-size-h2: '40px';
$font-size-h3: '30px';

// Headings Repsonsive Reductions
// Setup some sort of rem-calc mixin??? could be useful
