/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../../scss/abstracts/breakpoints';
@import '../../../../scss/abstracts/colors';
@import '../../../../scss/abstracts/mixins';
@import '../../../../scss/abstracts/typography';
@import '../../../../scss/abstracts/animation';
@import '../../../../scss/abstracts/variables';

// html.mobile,
// html.tablet {
//   .home-subheader {
//     height: 95vh;
//   }
// }
.home-subheader {
  background-color: #000;
  position: relative;
  overflow: hidden;
  height: 100vh;
  max-height: 800px;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.home-subheader__middle-region {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;

  .container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;

    @media only screen and (min-width: $screen-md-min) {
      flex-direction: row;
    }
  }
}

.home-subheader__middle-region .title {
  width: 100%;
  display: flex;
  justify-content: center;

  @media only screen and (min-width: $screen-md-min) {
    width: 70%;
  }

  @media only screen and (min-width: $screen-lg-min) {
    width: 60%;
  }

  h3 {
    text-align: center;
    color: #fff;
    font-family: $font__museo--1000;
    line-height: 1.2;
    margin-bottom: 20px;
    max-width: 475px;
    min-height: 202px;
    @include font-size(40);

    @media only screen and (min-width: $screen-md-min) {
      @include font-size(47);
      max-width: none;
      margin-bottom: 0;
      padding: 0 20px;
    }

    @media only screen and (min-width: $screen-lg-min) {
      @include font-size(56);
    }

    span {
      color: #47a7ce;
    }
  }
}

.home-subheader__bottom-region {
  position: absolute;
  bottom: 20px;
  width: 100%;

  .container {
    display: flex;
    align-items: center;
  }
}
