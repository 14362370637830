.current-vacancies__grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0 10px;
}
.current-vacancies__title {
  color: $color_pale_red;
  font-family: $font__museo__1000;
  @include font-size(30);
  margin-bottom: 30px;

  text-align: center;

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(45);
    padding: {
      right: 50px;
      left: 50px;
    }
  }

  @media only screen and (min-width: $screen-lg-min) {
    @include font-size(72);

    padding: {
      right: 100px;
      left: 100px;
    }
  }
}
