.category__header__container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;

  @media only screen and (min-width: $screen-sm-min) {
    padding: 40px 15px;
  }

  .title {
    color: #fff;
    font-family: $font__museo--1000;
    line-height: 1.2;
    @include font-size(28);
    position: relative;
    z-index: 1;
    opacity: 0;
    text-align: center;
    padding: 0 110px;
    animation: 0.5s ease 0.4s normal forwards 1 fadeIn;

    @media only screen and (min-width: $screen-md-min) {
      @include font-size(35);
    }

    @media only screen and (min-width: $screen-lg-min) {
      @include font-size(50);
    }
  }

  .description {
    color: #fff;
    line-height: 1.4;
    font-family: $font__museo--900;
    @include font-size(14);
    opacity: 0;
    max-width: 600px;
    margin-top: 20px;

    animation: 0.5s ease 0.7s normal forwards 1 fadeIn;

    position: relative;
    z-index: 1;
  }
}

.category__header__container .back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 10;

  @media only screen and (min-width: $screen-sm-min) {
    top: 40px;
  }

  animation: 0.5s ease 1s normal forwards 1 fadeIn;

  .go-back {
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 25px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    font-family: $font__museo--900;

    @include transition(border-color, color);

    .svg-icon--arrow-left {
      height: 18px;
      width: 12px;
      margin-right: 5px;

      @include transition(fill);
    }

    @media (hover: hover) {
      &:hover {
        border-color: $color_yellow;
        color: $color_yellow;

        .svg-icon--arrow-left {
          fill: $color_yellow;
        }
      }
    }
  }
}
