.modal-layout__client__logo {
  width: 100px;
  height: 100px;
  padding: 20px;
  background-color: $color_dark-grey;
  border-radius: 50%;

  margin-bottom: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-layout__client__title {
  color: #fff;
  font-family: $font__museo--1000;
  @include font-size(26);
  margin-bottom: 40px;
}

.modal-layout__project__list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.modal-layout__project__item {
  margin: 0 10px 30px;
  flex-basis: calc(50% - 20px);

  @media only screen and (min-width: $screen-md-min) {
    flex-basis: calc(33.3% - 20px);
  }
}

.modal-layout__project__details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-layout__project__details .title {
  color: #fff;
  font-family: $font__museo--900;
  @include font-size(11);
  text-align: center;
  margin-bottom: 20px;
}
.modal-layout__project__details .image {
  border-radius: 50%;
  width: 120px;
  height: 120px;

  margin-bottom: 20px;

  overflow: hidden;
  display: flex;
  justify-content: center;

  img {
    width: auto;
    height: 100%;
  }
}
.modal-layout__project__details .button {
  background-color: transparent;
  border: none;
  border: #fff 2px solid;
  border-radius: 15px;
  color: #fff;
  font-family: $font__museo--900;
  @include font-size(12);
  padding: 5px 10px;
  cursor: pointer;

  margin-bottom: 20px;
}
