.categories__grid {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.item {
  background: white;
  border-radius: 100%;
}
