.employee__image {
  max-width: 150px;
  max-height: 150px;

  margin-top: 30px;
  margin-bottom: 30px;

  @media only screen and (min-width: $screen-md-min) {
    max-width: 250px;
    max-height: 250px;

    margin-top: 0;
  }

  img {
    border-radius: 50%;
  }
}

.employee__name {
  font-family: $font__museo--1000;
  margin-bottom: 5px;
  color: #ffa5b3;
  @include font-size(24);

  @media only screen and (max-width: $screen-md-min) {
    text-align: center;
  }
}
.employee__job-title {
  @include font-size(18);
  color: #fff;
  margin-bottom: 30px;

  @media only screen and (max-width: $screen-md-min) {
    text-align: center;
  }
}
.employee__description {
  color: #fff;
  p {
    color: #fff;
  }

  li {
    margin-bottom: 10px;
  }
}

.employee__close {
  background-color: transparent;
  border: none;
  height: 50px;
  position: absolute;
  width: 50px;
  padding: 0;
  right: 0;
  top: 0px;
  cursor: pointer;

  @media only screen and (min-width: $screen-md-min) {
    left: 30px;
    right: auto;
  }

  .svg-icon--close {
    @include transition(fill);

    &:hover {
      fill: $color_yellow;
    }
  }
}
