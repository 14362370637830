@use 'sass:math';

// Helpful mixins

// Designs tend to be in points value
// We convert points into pixels then into rem
// formula : pixels = math.div(points, .75)
// General rule : 1pt = 1.33333px

@function pointsToPixels($value) {
  @return $value * (math.div(96, 72));
}

// *** getting tired of working out the font sizes from the designs

// Font size mixin - Usage:
// @include font-size(32);
// or if passing AI design points value
// @include font-size(48, true);
// params:
// --- $sizeValue = $default-text-size (see scss/site-variables/_typography.scss for font size variable)
// --- $pointsValue = false

@mixin font-size($sizeValue: $default-text-size, $pointsValue: false) {
  @if ($pointsValue) {
    font-size: pointsToPixels($sizeValue) + px;
    font-size: math.div(pointsToPixels($sizeValue), $default-text-size) + rem;
  } @else {
    font-size: $sizeValue + px;
    font-size: math.div($sizeValue, $default-text-size) + rem;
  }
}

// #{$property}: $ltr-value;
// Usage:   @include transition(width, height 0.3s ease-in-out);
// Output:  -webkit-transition(width 0.2s, height 0.3s ease-in-out);
//          transition(width 0.2s, height 0.3s ease-in-out);
//
// Pass in any number of transitions
@mixin transition($transitions...) {
  $unfoldedTransitions: ();
  @each $transition in $transitions {
    $unfoldedTransitions: append($unfoldedTransitions, unfoldTransition($transition), comma);
  }

  -webkit-transition: $unfoldedTransitions;
  transition: $unfoldedTransitions;
}

@function unfoldTransition($transition) {
  // Default values
  $property: all;
  $duration: 0.3s;
  $easing: ease-in-out; // Browser default is ease, but I like ease-in-out
  $delay: null; // Browser default is 0, which is what we want
  $defaultProperties: ($property, $duration, $easing, $delay);

  // Grab transition properties if they exist
  $unfoldedTransition: ();
  @for $i from 1 through length($defaultProperties) {
    $p: null;
    @if $i <= length($transition) {
      $p: nth($transition, $i);
    } @else {
      $p: nth($defaultProperties, $i);
    }
    $unfoldedTransition: append($unfoldedTransition, $p);
  }

  @return $unfoldedTransition;
}

@mixin scrollbars($size: 10px, $foreground-color: #999, $background-color: #333) {
  // For Chrome & Safari
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }
  &::-webkit-scrollbar-thumb {
    background: -moz-linear-gradient(222deg, rgba(71, 205, 199, 1) 0%, rgba(255, 145, 163, 1) 100%);
    background: -webkit-linear-gradient(222deg, rgba(71, 205, 199, 1) 0%, rgba(255, 145, 163, 1) 100%);
    background: linear-gradient(222deg, rgba(71, 205, 199, 1) 0%, rgba(255, 145, 163, 1) 100%);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // Standard version (Firefox only for now)
  scrollbar-color: $foreground-color $background-color;
}
