/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../scss/abstracts/breakpoints';
@import '../../../scss/abstracts/colors';
@import '../../../scss/abstracts/mixins';
@import '../../../scss/abstracts/typography';
@import '../../../scss/abstracts/animation';
@import '../../../scss/abstracts/variables';

.navigation__list {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 88%;

  li {
    height: 88%;
    width: 100%;
  }

  @media only screen and (min-width: $screen-md-min) {
    height: 100%;

    li {
      height: 100%;
    }
  }
}

.navigation--header .navigation__list {
  align-items: flex-end;

  @media only screen and (min-width: $screen-md-min) {
    align-items: center;
  }
}
