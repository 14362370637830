/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../scss/abstracts/breakpoints';
@import '../../../scss/abstracts/colors';
@import '../../../scss/abstracts/mixins';
@import '../../../scss/abstracts/typography';
@import '../../../scss/abstracts/animation';
@import '../../../scss/abstracts/variables';

.about {
  overflow: hidden;
  background-color: $color_pink;
  @media only screen and (max-width: $screen-sm-min) {
    background-color: #ffffff
  }
}

.about__employees {
  background: rgb(71, 197, 201);
  background: -moz-linear-gradient(90deg, rgba(71, 197, 201, 1) 0%, rgba(71, 167, 206, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(71, 197, 201, 1) 0%, rgba(71, 167, 206, 1) 100%);
  background: linear-gradient(90deg, rgba(71, 197, 201, 1) 0%, rgba(71, 167, 206, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#47c5c9",endColorstr="#47a7ce",GradientType=1);

  padding-top: 60px;
  padding-bottom: 60px;

  h2 {
    color: $color_pink;
    text-align: center;
    font-family: $font__museo--1000;
    margin-bottom: 40px;

    @include font-size(45);
  }
}

.about__timeline {
  //background-color: $color_pink;
  padding-top: 60px;
  //padding-bottom: 60px;
  position: relative;

  h3 {
    color: $color_red;
    text-align: center;
    font-family: $font__museo--1000;
    margin-bottom: 40px;

    @include font-size(45);
  }
}

.about__timeline .about__timeline__container .centered {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about__timeline .about__timeline__container {
  background-color: $color_white;
  transform: rotate(-0.2deg);
  margin-right: 20px;
  margin-left: 20px;
  @media only screen and (min-width: $screen-lg-min) {
    margin-right: 200px;
    margin-left: 200px;
  }

  @media only screen and (min-width: $screen-md-min) {
    transform: rotate(-0.5deg);
  }

  .container {
    position: relative;
    transform: rotate(0.2deg);
    padding-top: 60px;
    padding-bottom: 60px;
    @media only screen and (min-width: $screen-md-min) {
      transform: rotate(0.5deg);
    }
    @media only screen and (min-width: 600px) {
      width: 600px;
    }
  }
}

// SVG's
.about__timeline .svg-icon--yellow-lines-circle {
  position: absolute;
  top: -150px;
  left: -170px;

  width: 270px;

  display: none;
  @media only screen and (min-width: $screen-sm-min) {
    display: block;
  }

  @media only screen and (min-width: $screen-md-min) {
    left: -270px;
  }
}
.about__timeline .svg-icon--purple-lines {
  position: absolute;
  top: 10px;
  right: -200px;

  width: 250px;

  display: none;
  @media only screen and (min-width: $screen-sm-min) {
    display: block;
  }

  @media only screen and (min-width: $screen-md-min) {
    right: -250px;
  }
}
.about__timeline .svg-icon--red-lines-rectangle {
  position: absolute;
  top: 50%;
  right: -200px;

  transform: translateY(-50%);
  width: 300px;

  display: none;
  @media only screen and (min-width: $screen-sm-min) {
    display: block;
  }

  @media only screen and (min-width: $screen-md-min) {
    right: -300px;
  }
}
.about__timeline .svg-icon--green-lines-circle {
  position: absolute;
  top: 35%;
  left: -130px;

  width: 220px;

  display: none;
  @media only screen and (min-width: $screen-sm-min) {
    display: block;
  }

  @media only screen and (min-width: $screen-md-min) {
    left: -170px;
  }
}
.about__timeline .svg-icon--blue-lines-triangle {
  position: absolute;
  bottom: -150px;
  left: -200px;
  width: 220px;
  z-index: 10;

  display: none;
  @media only screen and (min-width: $screen-sm-min) {
    display: block;
  }

  @media only screen and (min-width: $screen-md-min) {
    left: -220px;
  }
}
.about__timeline .svg-icon--yellow-lines-circle2 {
  position: absolute;
  bottom: -160px;
  right: -170px;

  width: 220px;

  display: none;
  @media only screen and (min-width: $screen-sm-min) {
    display: block;
  }

  @media only screen and (min-width: $screen-md-min) {
    right: -200px;
  }
}
