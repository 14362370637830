.social-icons__list {
  text-align: center;
}

.social-icons__link {
  display: inline-block;
  margin: 3px;

  svg {
    fill: #fff;
  }
}
