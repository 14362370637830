/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../scss/abstracts/breakpoints';
@import '../../../scss/abstracts/colors';
@import '../../../scss/abstracts/mixins';
@import '../../../scss/abstracts/typography';
@import '../../../scss/abstracts/animation';
@import '../../../scss/abstracts/variables';

.project__header {
  height: min(50vw, 300px);
  display: flex;
  align-items: center;
  padding-right: 50px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;

  @media only screen and (min-width: $screen-md-min) {
    height: min(40vw, 400px);
  }
}

.project__header__container {
  position: relative;
  display: flex;
  padding: 10px 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0%;
    background-color: rgba(44, 161, 192, 0.9);

    animation: 0.5s ease 1s normal forwards 1 wipeIn;
  }

  .back-button {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;

    animation: 0.5s ease 2.3s normal forwards 1 fadeIn;

    @media only screen and (min-width: $screen-md-min) {
      width: 200px;
    }

    .go-back {
      background-color: transparent;
      border: 2px solid #fff;
      border-radius: 25px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
      text-decoration: none;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      font-family: $font__museo--900;

      @include transition(border-color, color);

      .svg-icon--arrow-left {
        height: 18px;
        width: 12px;
        margin-right: 5px;

        @include transition(fill);
      }

      &:hover {
        border-color: $color_yellow;
        color: $color_yellow;

        .svg-icon--arrow-left {
          fill: $color_yellow;
        }
      }
    }
  }

  .title {
    width: calc(100% - 120px);
    color: $color_yellow;
    font-family: $font__museo--1000;
    line-height: 1.2;
    @include font-size(17);

    opacity: 0;

    animation: 0.5s ease 1.7s normal forwards 1 fadeIn;

    position: relative;
    z-index: 1;
    margin-right: 10px;

    @media only screen and (min-width: $screen-sm-min) {
      @include font-size(25);
    }

    @media only screen and (min-width: $screen-md-min) {
      width: calc(100% - 200px);

      @include font-size(30);
    }

    @media only screen and (min-width: $screen-lg-min) {
      @include font-size(50);
    }
  }
}
