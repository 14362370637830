/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../../scss/abstracts/breakpoints';
@import '../../../../scss/abstracts/colors';
@import '../../../../scss/abstracts/mixins';
@import '../../../../scss/abstracts/typography';
@import '../../../../scss/abstracts/animation';
@import '../../../../scss/abstracts/variables';

.clr-process1 {
  fill: $color_process1;
}

.clr-process2 {
  fill: $color_process2;
}

.clr-process3 {
  fill: $color_process3;
}

.clr-process4 {
  fill: $color_process4;
}

.clr-process5 {
  fill: $color_process5;
}

.clr-process6 {
  fill: $color_process6;
}

.isolate {
  isolation: isolate;
}
