.contact-becoming-jollywise {
  background-color: #fff;
  transform: rotate(0);

  padding: 40px 0 60px;
  margin-bottom: -30px;

  @media only screen and (min-width: $screen-md-min) {
    transform: rotate(-1deg);
  }
}

.contact-becoming-jollywise__container {
  transform: rotate(0);

  padding-right: 50px;
  padding-left: 50px;

  @media only screen and (min-width: $screen-md-min) {
    transform: rotate(1deg);
  }
}

.contact-becoming-jollywise__testimonials {
  display: flex;
  flex-wrap: wrap;
}

.contact-becoming-jollywise__title {
  color: $color_yellow;
  font-family: $font__museo--1000;
  margin-bottom: 20px;
  @include font-size(30);
  text-align: center;

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(45);
    padding: {
      right: 50px;
      left: 50px;
    }
  }

  @media only screen and (min-width: $screen-lg-min) {
    @include font-size(72);

    padding: {
      right: 100px;
      left: 100px;
    }
  }
}

.contact-becoming-jollywise__description {
  color: $color_dark-grey;
  padding-bottom: 60px;
  font-family: $font__museo--500;
  line-height: 1.4;
  text-align: center;

  @include font-size(16);

  padding: {
    right: 15px;
    left: 15px;
  }

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(18);

    padding: {
      right: 100px;
      left: 100px;
    }
  }

  @media only screen and (min-width: $screen-lg-min) {
    @include font-size(24);

    padding: {
      right: 240px;
      left: 240px;
    }
  }
}

.contact-becoming-jollywise__testimonial {
  flex-basis: calc(50% - 20px);
  margin: 0 10px 10px;

  @media only screen and (min-width: $screen-md-min) {
    flex-basis: calc(33.3% - 20px);
  }
}
