.image-collages__grid-one__grid {
  display: flex;
  flex-wrap: wrap;
  line-height: 0;
  min-height: 200px;

  figure {
    position: relative;
    width: 100%;
    height: 100%;
  }

  img {
    opacity: 0.6;
  }

  .left {
    display: flex;
    flex-wrap: wrap;

    @media only screen and (min-width: $screen-sm-min) {
      flex-basis: 50%;
    }

    .left {
      flex-basis: 50%;
      overflow: hidden;
      position: relative;

      background-color: #c362a3;

      img {
        position: absolute;
        width: auto;
        height: 100%;
      }
    }

    .right {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;

      &--first {
        background-color: #47a7ce;
      }

      &--second {
        background-color: #ffd208;
      }
    }
  }

  .right {
    background-color: #6ab163;

    @media only screen and (min-width: $screen-sm-min) {
      flex-basis: 50%;
    }
  }
}

.image-collages__grid-two__grid {
  display: flex;
  flex-wrap: wrap;
  line-height: 0;
  min-height: 200px;

  figure {
    position: relative;
    width: 100%;
    height: 100%;
  }

  img {
    opacity: 0.6;
  }

  .left {
    background-color: #6ab163;

    @media only screen and (min-width: $screen-sm-min) {
      flex-basis: 50%;
    }
  }

  .right {
    display: flex;
    flex-wrap: wrap;

    @media only screen and (min-width: $screen-sm-min) {
      flex-basis: 50%;
    }

    .left {
      flex-basis: 50%;
      overflow: hidden;
      position: relative;

      background-color: #c362a3;

      img {
        position: absolute;

        width: auto;
        height: 100%;
      }
    }

    .right {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;

      .right--first {
        background-color: #47a7ce;
        height: 50%;
      }

      .right--second {
        height: 50%;

        background-color: #ffd208;
      }
    }
  }
}
