/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../../scss/abstracts/breakpoints';
@import '../../../../scss/abstracts/colors';
@import '../../../../scss/abstracts/mixins';
@import '../../../../scss/abstracts/typography';
@import '../../../../scss/abstracts/animation';
@import '../../../../scss/abstracts/variables';

.about-header {
  height: calc(100vh - 70px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.2;
  }
}

.about-header .svg-icon--liney-lines {
  position: absolute;
  width: 250px;
  left: -100px;
  top: 70%;
  transform: translateY(0);

  @media only screen and (min-width: $screen-sm-min) {
    width: 350px;
  }

  @media only screen and (min-width: $screen-md-min) {
    width: 500px;
  }

  @media only screen and (orientation: portrait) and (max-width: 375px) {
    top: 75%;
  }
}

.about-header .svg-icon--yellow-line-lines {
  position: absolute;
  width: 250px;
  right: -100px;
  top: -50px;

  @media only screen and (min-width: $screen-sm-min) {
    width: 350px;
  }

  @media only screen and (min-width: $screen-md-min) {
    width: 500px;
  }
}

.about-header__container {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  height: 100%;
}

.about-header__title {
  position: relative;

  text-align: center;
  color: #fff;
  font-family: $font__museo__1000;
  margin: 20px 0 40px;

  @include font-size(45);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(60);
  }

  @media only screen and (min-width: $screen-lg-min) {
    @include font-size(95);

    padding: {
      right: 120px;
      left: 120px;
    }
  }
}

.about-header__description {
  color: #fff;
  text-align: center;

  padding: {
    right: 15px;
    left: 15px;
  }

  width: 100%;
  max-width: 780px;
  margin: 0 auto;

  line-height: 1.4;
  font-family: $font__museo--300;

  @include font-size(16);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(24);
  }
}

.about-header__arrow {
  position: absolute;
  bottom: 30px;

  @media only screen and (min-width: $screen-md-min) {
    animation: bounce 2s infinite 1s;
    bottom: 40px;
  }

  button {
    background: transparent;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    border: 3px solid #fff;
    @include transition(border-color);

    @media (hover: hover) {
      &:hover {
        border-color: $color_yellow;

        .svg-icon--arrow-down {
          fill: $color_yellow;
        }
      }
    }
  }
  .svg-icon--arrow-down {
    height: 21px;
    width: 21px;

    @include transition(fill);
  }
}
