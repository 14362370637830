.creative-services {
  background: rgb(72, 206, 199);
  background: -moz-linear-gradient(180deg, rgba(72, 206, 199, 1) 0%, rgba(251, 146, 164, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(72, 206, 199, 1) 0%, rgba(251, 146, 164, 1) 100%);
  background: linear-gradient(180deg, rgba(72, 206, 199, 1) 0%, rgba(251, 146, 164, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#48cec7",endColorstr="#fb92a4",GradientType=1);

  .container {
    padding: 0;
  }
}
