.illustrations__block {
  display: flex;
  // justify-content: center;
  align-items: center;
  position: relative;

  transform-origin: center;
  @include transition(transform 0.5s);

  flex-basis: 100%;
  padding-top: 76.1%;

  @media only screen and (min-width: $screen-sm-min) {
    flex-basis: 50%;
    padding-top: 38%;
  }

  @media only screen and (min-width: $screen-md-min) {
    flex-basis: 20%;
    padding-top: 15.3%;
  }
}

.illustrations__block__container--last-row .illustrations__block__desc {
  bottom: 100%;
  top: auto;
  display: flex;
  align-items: flex-end;
  padding: 0px 15px 15px;
}

.illustrations__block__arrow-icon {
  position: absolute;
  z-index: 20;
  bottom: 15px;
  left: 15px;

  .btn {
    background: none;
    border: none;
    color: #444;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    outline: none;
    transition: all 0.2s cubic-bezier(0, 0, 0.3, 1);

    outline: none;
  }
  .btn__icon {
    width: 20px;
    height: 20px;
    fill: #444;
    transition: all 0.25s cubic-bezier(0, 0, 0.3, 1);
  }

  .btn__icon--top {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.illustrations__block__desc {
  position: absolute;
  top: 100%;
  height: auto;
  width: 100%;
  left: 0;
  color: #fff;
  opacity: 0;
  padding: 15px 15px 0;

  @include transition(opacity 0.5s);
}

.illustrations__block__background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  opacity: 1;
  @include transition(transform, opacity);
  z-index: 10;

  // @media only screen and (max-width: 768px) {
  //   opacity: 0;
  // }
}

.illustrations__block--show {
  transform: scale(1);
}

.illustrations__block__video {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.illustrations__block__inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  overflow: hidden;

  &--loading::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.1) 100%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

.illustrations__block__container--active {
  z-index: 1001;
  .illustrations__block__background-image {
    opacity: 0;
  }
  .illustrations__block__desc {
    opacity: 1;
    z-index: 100;
  }
  .btn--flip-out {
    .btn__icon--bottom {
      transform: translateY(-7.75%);
    }

    .btn__icon--top {
      transform: rotateX(-180deg) translateY(-7px);
    }
  }
}
