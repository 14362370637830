.blogs__block {
  flex-basis: 100%;
  opacity: 0;
  background-color: #fff;

  @media only screen and (min-width: $screen-md-min) {
    flex-basis: 50%;

    &--wide {
      flex-basis: 100%;
    }
  }
}

.blogs__block__image {
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;

  img {
    top: 50%;
    left: 50%;
    position: absolute;
    width: 120%;
    transform: translate(-50%, -50%);
  }
}

.blogs__block {
  margin-bottom: 20px;

  @media only screen and (min-width: $screen-md-min) {
    margin-bottom: 0;
  }
  figcaption {
    position: absolute;
    bottom: 30px;
    max-width: 90%;
    line-height: 1.2;
    padding: 5px 20px;

    font-family: $font__museo__900;
    @include font-size(16);

    @media only screen and (min-width: 400px) {
      @include font-size(22);
    }

    @media only screen and (min-width: $screen-md-min) {
      @include font-size(24);
    }

    @media only screen and (min-width: $screen-xl-min) {
      @include font-size(36);
    }
  }

  &--wide {
    @media only screen and (min-width: $screen-md-min) {
      .blogs__block__body {
        width: 80%;
        margin: 60px auto;
      }
    }

    figcaption {
      font-family: $font__museo__1000;

      @media only screen and (min-width: $screen-md-min) {
        @include font-size(42);

        bottom: 60px;
        padding-left: 80px;
      }

      @media only screen and (min-width: $screen-xl-min) {
        @include font-size(72);
      }
    }
  }

  &:nth-child(5n + 1) {
    figcaption {
      color: $color_yellow;
      background-color: rgba(44, 161, 192, 0.9);
    }
  }
  &:nth-child(5n + 2) {
    figcaption {
      color: $color_dark-grey;
      background-color: rgba(252, 201, 33, 0.9);
    }
  }
  &:nth-child(5n + 3) {
    figcaption {
      color: #fff;
      background-color: rgba(202, 91, 89, 0.9);
    }
  }
  &:nth-child(5n + 4) {
    figcaption {
      color: $color_yellow;
      background-color: rgba(205, 119, 161, 0.9);
    }
  }
  &:nth-child(5n + 5) {
    figcaption {
      color: #fff;
      background-color: rgba(71, 156, 195, 0.9);
    }
  }
}

.blogs__block__body {
  display: flex;
  margin: 60px 30px;
}

.blogs__block__intro {
  line-height: 1.4;

  @include font-size(14);

  @media only screen and (max-width: $screen-md-min) {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(18);
  }
}

.blogs__block__date {
  font-family: $font__museo--900;
  @include font-size(14);
  margin-bottom: 10px;
}

.blogs__block__left-section {
  flex-basis: calc(100% - 15px);
  margin-right: 15px;
}

.blogs__block__right-section {
  flex-basis: 130px;
  margin-left: auto;
}

.blogs__block__link {
  border: 2px solid $color_dark-grey;
  border-radius: 25px;
  color: $color_dark-grey;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  font-family: $font__museo--900;

  @include font-size(14);
  @include transition(border-color, color);

  &:hover {
    border-color: $color_yellow;
    color: $color_yellow;
  }
}
