/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../scss/abstracts/breakpoints';
@import '../../../scss/abstracts/colors';
@import '../../../scss/abstracts/mixins';
@import '../../../scss/abstracts/typography';
@import '../../../scss/abstracts/animation';
@import '../../../scss/abstracts/variables';

.navigation {
  width: 100%;

  display: flex;
}

// header nav styling
.navigation--header {
  position: absolute;
  top: 70px;
  left: 0;
  height: calc(100vh - 70px);
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);

  @include transition(left);

  // MMOBILE NAV STYLING ONLY!!
  @media only screen and (max-width: $screen-md-min) {
    &.navigation--close {
      left: 100%;
    }

    &.navigation--open {
      left: 0;
    }
  }

  @media only screen and (min-width: $screen-md-min) {
    height: 100%;
    position: relative;
    top: 0;
    background-color: transparent;
  }

  .navigation__list {
    flex-direction: column;
    width: 100%;

    @media only screen and (min-width: $screen-sm-min) {
      width: 80%;
      margin-left: auto;
    }

    @media only screen and (min-width: $screen-md-min) {
      width: 100%;
      margin-left: 0;
      flex-direction: row;
    }
  }
}
