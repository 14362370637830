.employees__item {
  margin: {
    right: 10px;
    bottom: 20px;
    left: 10px;
  }
  position: relative;

  &--loading {
    padding-top: 18%;

    .employees__item__image {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
    }
  }
  flex-basis: calc(100% / 3 - 20px);

  @media only screen and (min-width: $screen-sm-min) {
    flex-basis: calc(100% / 4 - 20px);
  }

  @media only screen and (min-width: $screen-md-min) {
    flex-basis: calc(100% / 5 - 20px);
  }

  @media only screen and (min-width: $screen-lg-min) {
    flex-basis: calc(100% / 6 - 20px);
  }
}

.employees__item__image {
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  background-color: $color_dark-grey;
  cursor: pointer;

  &:hover {
    img {
      opacity: 0;
      transform: scale(1.4);
      @include transition(transform, opacity);
    }

    figcaption {
      opacity: 1;
      transform: scale(1);
      @include transition(transform 0.3s ease-in-out 0.2s, opacity 0.3s ease-in-out 0.2s);
    }
  }

  img {
    transform: scale(1);
    opacity: 1;
    transform-origin: center center;
    @include transition(transform 0.3s ease-in-out 0.2s, opacity 0.3s ease-in-out 0.2s);
  }

  figcaption {
    opacity: 0;
    line-height: 1.1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;

    transform: scale(0.8);
    color: #fff;
    font-family: $font__museo--1000;
    transform-origin: center center;
    @include font-size(16);
    @include transition(transform, opacity);

    @media only screen and (min-width: $screen-md-min) {
      @include font-size(18);
    }

    @media only screen and (min-width: $screen-lg-min) {
      @include font-size(20);
    }
  }
}
