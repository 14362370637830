/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../../scss/abstracts/breakpoints';
@import '../../../../scss/abstracts/colors';
@import '../../../../scss/abstracts/mixins';
@import '../../../../scss/abstracts/typography';
@import '../../../../scss/abstracts/animation';
@import '../../../../scss/abstracts/variables';

.stroke {
  fill: none;
  stroke-width: 6;
}

.stroke-small {
  fill: none;
  stroke-width: 6;
  stroke: #f5f4f5;
}

.stroke-medium {
  fill: none;
  stroke-width: 6;
  stroke: #ffd207;
}

.stroke-large {
  fill: none;
  stroke-width: 6;
  stroke: #f18ea3;
}
