.employee-testimonial__block {
  flex-basis: calc(100% - 20px);
  margin: 0 10px 10px;
  padding-top: 80%;
  color: $color_dark-grey;
  border-radius: 2px;

  @media only screen and (min-width: $screen-md-min) {
    flex-basis: calc(50% - 20px);
    padding-top: 40%;
  }

  @media only screen and (min-width: $screen-lg-min) {
    flex-basis: calc(33.3% - 20px);
    padding-top: 30%;
  }
}

.employee-testimonial__block {
  position: relative;

  .svg-postit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.employee-testimonial__block .svg-postit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
}

.employee-testimonial__block__details {
  position: absolute;
  z-index: 2;
  top: 35px;
  left: 50%;
  width: 80%;
  transform: translateX(-50%);

  @media only screen and (min-width: 500px) {
    top: 75px;
  }

  @media only screen and (min-width: $screen-md-min) {
    top: 50px;
  }
}

.employee-testimonial__block__title {
  font-family: $font__museo--900;
  margin-bottom: 10px;
  text-align: center;
  width: 50%;
  left: -50%;
  transform: translateX(50%);

  @include font-size(16);
  @media only screen and (min-width: $screen-md-min) {
    @include font-size(20);
  }

  @media only screen and (min-width: $screen-xl-min) {
    @include font-size(24);
  }
}
.employee-testimonial__block__description {
  margin-bottom: 10px;
  line-height: 1.4;
  text-align: left;

  @include font-size(13);

  @media only screen and (min-width: 450px) {
    @include font-size(16);
  }
  @media only screen and (min-width: $screen-md-min) {
    @include font-size(14);
  }
  @media only screen and (min-width: $screen-xl-min) {
    @include font-size(16);
  }
}
.employee-testimonial__block__description.center {
  text-align: center;
}
.employee-testimonial__block__name {
  font-family: $font__museo--900;
  @include font-size(16);
  @media only screen and (min-width: $screen-md-min) {
    @include font-size(18);
  }
}

.employee-testimonial__block__details.pink .employee-testimonial__block__title {
  color: #c362a3;
}
.employee-testimonial__block__details.yellow .employee-testimonial__block__title {
  color: #ec685b;
}
.employee-testimonial__block__details.blue .employee-testimonial__block__title {
  color: #ffd208;
}
