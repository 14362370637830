.creative-services__cta-grid {
  display: flex;

  flex-direction: column;

  @media only screen and (min-width: $screen-md-min) {
    flex-direction: row;
  }
}

// SVG's
.creative-services__cta-grid {
  position: relative;
  overflow: hidden;

  svg {
    position: absolute;
    z-index: 10;

    @media only screen and (max-width: $screen-md-min) {
      display: none;
    }
  }

  .svg-icon--orange-squares {
    top: 0;
    left: 0;
    transform: translate(-30%, -40%);
    width: 150px;

    g {
      stroke: #ffd208;
    }

    @media only screen and (min-width: $screen-lg-min) {
      width: 300px;
    }
  }

  .svg-icon--blue-lines-triangle {
    bottom: 0;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translate(0%, 40%);

    g {
      stroke: #c164a2;
    }

    @media only screen and (min-width: $screen-lg-min) {
      width: 160px;
      height: 160px;
    }
  }

  .svg-icon--yellow-line-lines {
    bottom: 0;
    right: 0;
    width: 180px;
    transform: translate(10%, 30%);

    @media only screen and (min-width: $screen-lg-min) {
      width: 340px;
    }
  }

  .svg-icon--yellow-lines-circle {
    right: 15%;
    top: 0;
    width: 70px;
    height: 70px;
    transform: translate(0%, -60%);

    g {
      stroke: #ffd208;
    }

    @media only screen and (min-width: $screen-lg-min) {
      width: 140px;
      height: 140px;
    }
  }
  .svg-icon--red-lines {
    top: 0;
    width: 60px;
    height: 100px;
    left: 30%;
    transform: translate(0%, -40%);

    g {
      stroke: #4da8c7;
    }

    @media only screen and (min-width: $screen-lg-min) {
      width: 180px;
      height: 200px;
    }
  }
}
