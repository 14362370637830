.current-vacancies__block {
  flex-basis: 100%;
  opacity: 0;
  background-color: #fff;
  color: $color_dark-grey;

  figure {
    padding-top: 56.25%;
    position: relative;
    overflow: hidden;

    figcaption {
      position: absolute;
      bottom: 30px;
      max-width: 90%;
      line-height: 1;
      padding: 5px 20px;

      font-family: $font__museo__900;
      @include font-size(22);
      color: $color_yellow;
      background-color: rgba(44, 161, 192, 0.9);

      @media only screen and (min-width: $screen-md-min) {
        bottom: 60px;
        padding-left: 80px;

        font-family: $font__museo__1000;
        @include font-size(36);
      }
    }
  }
}

.current-vacancies__block__image {
  top: 0;
  left: 0;
  position: absolute;
}

.current-vacancies__block__body {
  display: flex;
  flex-direction: column;
  margin: 5px;

  @media only screen and (min-width: $screen-md-min) {
    margin: 25px;
    width: 80%;
    margin: 30px auto;
    flex-direction: row;
  }
}

.current-vacancies__block__intro {
  line-height: 1.4;
  @include font-size(14);
  margin-bottom: 15px;

  @media only screen and (max-width: $screen-md-min) {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(16);
  }
}

.current-vacancies__block__date {
  font-family: $font__museo--900;
  @include font-size(14);
  margin-bottom: 10px;
}

.current-vacancies__block__left-section {
  flex-basis: calc(100% - 15px);
  margin-right: 15px;
}

.current-vacancies__block__right-section {
  flex-basis: 190px;
  margin-left: auto;
}

.current-vacancies__block__link {
  border: 2px solid $color_dark-grey;
  border-radius: 25px;
  color: $color_dark-grey;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  font-family: $font__museo--900;

  @include font-size(12);
  @include transition(border-color, color);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(14);
  }

  &:hover {
    border-color: $color_yellow;
    color: $color_yellow;
  }
}
