.illustrations__grid {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  li {
    position: relative;
  }

  &.active {
    li .illustrations__block__container:not(.illustrations__block__container--active) {
      opacity: 0.1;
      @include transition(opacity 0.5);
    }
  }
}

.item {
  background: #000;
  border-radius: 100%;
}
