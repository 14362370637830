.category__grid {
  .category__block {
    flex-basis: 50%;
    padding-top: 33%;

    @media only screen and (min-width: $screen-sm-min) {
      flex-basis: 33.3%;
      padding-top: 23%;
    }

    @media only screen and (min-width: $screen-md-min) {
      flex-basis: 25%;
      padding-top: 19%;
    }
  }

  &--3 {
    .category__block {
      flex-basis: 100%;
      padding-top: 60%;

      @media only screen and (min-width: $screen-sm-min) {
        flex-basis: 33.3%;
        padding-top: 23%;
      }
    }
  }
}

.category__block {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  background-color: rgba(0, 0, 0, 0.4);

  &:hover {
    .category__block__background-image {
      transform: scale(1.2);
    }
  }
}

.category__block--show {
  transform: scale(1);
}

.category__block__background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;

  opacity: 0.4;
  transform-origin: center;
  transform: scale(1);
  @include transition(transform);
}

.category__block__inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  text-decoration: none;
}

.category__block__title {
  color: #fff;
  font-family: $font__museo__900;
  margin: {
    top: 45px;
    right: 15px;
    bottom: 15px;
    left: 15px;
  }
  @include font-size(18);
  text-align: center;

  @media only screen and (min-width: 400px) {
    @include font-size(30);
  }

  @media only screen and (min-width: $screen-sm-min) {
    @include font-size(26);
  }

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(16);
  }

  @media only screen and (min-width: $screen-lg-min) {
    @include font-size(20);
  }

  @media only screen and (min-width: $screen-xl-min) {
    @include font-size(26);
  }
}

.category__block__cta {
  color: #fff;
  font-family: $font__museo__700;
  @include font-size(11);

  text-align: center;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(13);
  }
}

.category__block__description {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  @include font-size(13);
  color: #fff;
  font-family: $font__museo__900;

  @media only screen and (min-width: $screen-sm-min) {
    @include font-size(18);
  }

  @media only screen and (min-width: $screen-md-min) and (max-width: $screen-lg-min) {
    @include font-size(14);
  }
  @media only screen and (min-width: $screen-lg-min) {
    @include font-size(18);
  }
}
