.svg-postit--blue .clr {
  fill: #5ABEBE;
}

.svg-postit--pink .clr {
  fill: #F18FA3;
}

.svg-postit--yellow .clr {
  fill: #FFD205;
}


.svg-icon--testimonial-bg .cls-1 {
  fill: $color_yellow;
}
.svg-icon--viemo {
  fill: #fff;
  width: 16px;
  height: 16px;
}
.svg-icon--yellow-line-lines .cls-1 {
  fill: none;
  stroke: #ffd208;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 10px;
}
.svg-icon--liney-lines .cls-1 {
  fill: none;
  stroke: #ff91a3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 10px;
}
.svg-icon--close {
  fill: #fff;
}
.svg-icon--arrow-left {
  fill: #fff;
  height: 18px;
  width: 12px;
  margin-right: 5px;
}
.svg-icon--arrow-down {
  fill: #fff;
  height: 12px;
  width: 18px;
}
