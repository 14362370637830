.contact-join-us {
  background-size: auto;
  background-position: center top;
  background-repeat: no-repeat;
  position: relative;

  @media only screen and (min-width: $screen-md-min) {
    background-size: 100%;
  }
}

.contact-join-us__container {
  padding-top: 60px;
  padding-bottom: 60px;
}

.contact-join-us__container .container {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  height: 100%;
}

.contact-join-us__contact-becoming-jollywise {
  //overflow: hidden;
}

.contact-join-us .svg-icon--blue-dots {
  position: absolute;
  width: 250px;
  left: -100px;
  top: 150px;
  z-index: 10;

  @media only screen and (min-width: $screen-sm-min) {
    width: 350px;
  }

  @media only screen and (min-width: $screen-md-min) {
    width: 500px;
  }
}

.contact-join-us__title {
  color: #fff;
  font-family: $font__museo--1000;
  margin-bottom: 20px;
  text-align: center;
  @include font-size(30);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(45);

    padding: {
      right: 50px;
      left: 50px;
    }
  }

  @media only screen and (min-width: $screen-lg-min) {
    @include font-size(72);

    padding: {
      right: 100px;
      left: 100px;
    }
  }
}

.contact-join-us__description {
  color: #f5f5f5;
  font-family: $font__museo--700;
  text-align: center;
  @include font-size(16);
  line-height: 1.4;

  padding: {
    right: 50px;
    left: 50px;
  }

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(18);

    padding: {
      right: 100px;
      left: 100px;
    }
  }

  @media only screen and (min-width: $screen-lg-min) {
    @include font-size(24);

    padding: {
      right: 250px;
      left: 250px;
    }
  }
}
