/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../scss/abstracts/breakpoints';
@import '../../../scss/abstracts/colors';
@import '../../../scss/abstracts/mixins';
@import '../../../scss/abstracts/typography';
@import '../../../scss/abstracts/animation';
@import '../../../scss/abstracts/variables';

.navigation__mobile-button {
  display: flex;
  flex-direction: column;
  width: 40px;
  cursor: pointer;
  margin-left: auto;

  @media only screen and (min-width: $screen-md-min) {
    display: none;
  }
}

.navigation__mobile-button__line-1,
.navigation__mobile-button__line-2,
.navigation__mobile-button__line-3 {
  background: $color_dark-grey;
  border-radius: 10px;
  height: 4px;
  margin: 4px 0;
  transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.navigation__mobile-button__line-1 {
  width: 50%;
}

.navigation__mobile-button__line-2 {
  width: 100%;
}

.navigation__mobile-button__line-3 {
  width: 75%;
}

.navigation__mobile-button--close {
  .navigation__mobile-button__line-1,
  .navigation__mobile-button__line-2,
  .navigation__mobile-button__line-3 {
    width: 100%;
  }
}

.navigation__mobile-button--open .navigation__mobile-button__line-1 {
  transform-origin: bottom;
  transform: rotatez(45deg) translate(5px, 0px);
}

.navigation__mobile-button--open .navigation__mobile-button__line-2 {
  transform-origin: top;
  transform: rotatez(-45deg);
}

.navigation__mobile-button--open .navigation__mobile-button__line-3 {
  transform-origin: bottom;
  width: 50%;
  transform: translate(18px, -6px) rotatez(45deg);
}
