.blog__related_grid {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.blog__related_grid__title {
  color: $color_yellow;
  font-family: $font__museo--1000;
  line-height: 1.2;
  @include font-size(17);

  padding-left: 15px;

  @media only screen and (min-width: $screen-sm-min) {
    @include font-size(25);
  }

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(30);
  }
}
