/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../../scss/abstracts/breakpoints';
@import '../../../../scss/abstracts/colors';
@import '../../../../scss/abstracts/mixins';
@import '../../../../scss/abstracts/typography';
@import '../../../../scss/abstracts/animation';
@import '../../../../scss/abstracts/variables';

.contact-header {
  padding: 40px 0;
  overflow: hidden;
  position: relative;

  @media only screen and (min-width: $screen-md-min) {
    padding: 80px 0;
  }
}

.contact-header__container {
  position: relative;
}

.contact-header__heroimage {
  position: relative;

  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 90%;
  @media only screen and (min-width: $screen-md-min) {
    width: 100%;
    max-width: 700px;
  }
  margin-bottom: 20px;
}

.contact-header__description {
  position: relative;

  text-align: center;
  color: #fff;
  line-height: 1.4;

  font-family: $font__museo--300;

  padding: {
    right: 15px;
    left: 15px;
  }

  width: 100%;
  max-width: 780px;
  margin: auto;

  @include font-size(16);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(24);
  }
}

// SVG's
.contact-header .svg-icon--red-swirls {
  position: absolute;
  width: 250px;
  left: -100px;
  top: -100px;

  @media only screen and (min-width: $screen-sm-min) {
    width: 350px;
  }

  @media only screen and (min-width: $screen-md-min) {
    width: 400px;
  }
}

.contact-header .svg-icon--yellow-lines {
  position: absolute;
  width: 200px;
  right: -100px;
  top: 50px;

  @media only screen and (min-width: $screen-sm-min) {
    width: 250px;
  }

  @media only screen and (min-width: $screen-md-min) {
    width: 400px;
  }
}
