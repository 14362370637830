.category {
  background: rgb(245, 167, 58);
  background: -moz-linear-gradient(90deg, rgba(245, 167, 58, 1) 0%, rgba(234, 105, 95, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(245, 167, 58, 1) 0%, rgba(234, 105, 95, 1) 100%);
  background: linear-gradient(90deg, rgba(245, 167, 58, 1) 0%, rgba(234, 105, 95, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5a73a",endColorstr="#ea695f",GradientType=1);

  padding-bottom: 40px;

  &--illustration {
    background: rgb(104, 175, 113);
    background: -moz-linear-gradient(90deg, rgba(104, 175, 113, 1) 0%, rgba(84, 170, 177, 1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(104, 175, 113, 1) 0%, rgba(84, 170, 177, 1) 100%);
    background: linear-gradient(90deg, rgba(104, 175, 113, 1) 0%, rgba(84, 170, 177, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#68af71",endColorstr="#54aab1",GradientType=1);

    padding-bottom: 0;

    > .container {
      @media only screen and (min-width: $screen-md-min) {
        overflow: hidden;
      }
    }
  }

  .container {
    padding: 0;
    position: relative;
  }
}
