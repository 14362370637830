// SVG's
.category .svg-icon--blue-dots {
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
  width: 400px;
  left: -150px;

  display: none;
  @media only screen and (min-width: $screen-sm-min) {
    display: block;
  }
}
.category .svg-icon--orange-squares {
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
  right: -150px;
  width: 400px;

  display: none;
  @media only screen and (min-width: $screen-sm-min) {
    display: block;
  }

  @media only screen and (min-width: $screen-md-min) {
    right: -50px;
  }
}
