$form-clr: $color_cyan;

.contact-form {
  color: $form-clr;
  padding: 40px;
  margin-bottom: 100px;
}

.contact-form h2 {
  color: #ff91a3;
  text-align: center;

  font-family: $font__museo--1000;
  margin-bottom: 20px;
  @include font-size(30);
  text-align: center;

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(45);
    padding: {
      right: 50px;
      left: 50px;
    }
  }

  @media only screen and (min-width: $screen-lg-min) {
    padding: {
      right: 100px;
      left: 100px;
    }
  }
}

.contact-form__form {
  display: flex;
  flex-wrap: wrap;

  .form-error {
    color: $color_red;
    font-family: $font__museo--700;
    @include font-size(14);
  }

  label {
    font-family: $font__museo--700;
    display: block;
    margin-bottom: 10px;
    color: $color_dark-grey;

    @include font-size(20);

    @media only screen and (min-width: $screen-md-min) {
      @include font-size(18);
    }

    @media only screen and (min-width: $screen-lg-min) {
      @include font-size(24);
    }
  }

  textarea,
  input {
    width: 100%;

    border: 2px solid $form-clr;
    border-radius: 12px;

    min-height: 50px;
    padding: 5px;
    font-family: $font__museo--700;
    color: $color_dark-grey;
    margin-bottom: 20px;

    @include font-size(16);

    @media only screen and (min-width: $screen-md-min) {
      @include font-size(14);
    }

    @media only screen and (min-width: $screen-lg-min) {
      @include font-size(18);
    }
  }

  textarea::placeholder {
    font-family: $font__museo--300;
    color: #999;

    @include font-size(16);

    @media only screen and (min-width: $screen-md-min) {
      @include font-size(14);
    }

    @media only screen and (min-width: $screen-lg-min) {
      @include font-size(18);
    }
  }

  input::placeholder {
    font-family: $font__museo--300;
    color: #999;

    @include font-size(16);

    @media only screen and (min-width: $screen-md-min) {
      @include font-size(14);
    }

    @media only screen and (min-width: $screen-lg-min) {
      @include font-size(18);
    }
  }

  textarea {
    min-height: 150px;

    @include font-size(16);

    @media only screen and (min-width: $screen-md-min) {
      @include font-size(14);
    }

    @media only screen and (min-width: $screen-lg-min) {
      @include font-size(18);
    }
  }

  .firstname,
  .lastname {
    flex-basis: 100%;

    @media only screen and (min-width: $screen-md-min) {
      flex-basis: calc(50% - 20px);
      margin: 10px;
    }
  }

  .email {
    flex-basis: 100%;

    @media only screen and (min-width: $screen-md-min) {
      flex-basis: calc(100% - 20px);
      margin: 10px;
    }
  }

  .tel {
    display: none;
    visibility: hidden;
  }

  .message,
  .submit-button {
    flex-basis: 100%;

    @media only screen and (min-width: $screen-md-min) {
      margin: 10px;
    }
  }

  .submit-button {
    display: flex;
    justify-content: center;
  }

  .send {
    cursor: pointer;
    background-color: transparent;
    align-items: center;
    border: 3px solid $form-clr;
    border-radius: 25px;
    color: $form-clr;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 10px 15px;
    position: relative;
    text-decoration: none;
    @include transition(background-color, color);
    font-family: $font__museo--700;

    &:hover {
      background-color: $form-clr;
      color: $color_yellow;
    }
  }

  .sending {
    cursor: pointer;
    background-color: transparent;
    align-items: center;
    border: 2px solid $form-clr;
    border-radius: 25px;
    color: $form-clr;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 10px 15px;
    position: relative;
    text-decoration: none;
    @include transition(background-color, color);
    font-family: $font__museo--700;
  }
}

.contact-form__fail,
.contact-form__success {
  width: 100%;
  font-family: $font__museo--700;
  @include font-size(18);
  text-align: center;

  .submit-button {
    display: flex;
    justify-content: center;
  }

  button {
    margin-top: 20px;
    cursor: pointer;
    background-color: transparent;
    align-items: center;
    border: 2px solid $form-clr;
    border-radius: 25px;
    color: $form-clr;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 10px 15px;
    position: relative;
    text-decoration: none;
    @include transition(background-color, color);
    font-family: $font__museo--700;

    &:hover {
      background-color: $form-clr;
      color: $color_yellow;
    }
  }
}
