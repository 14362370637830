body {
  @include font-size(16);
  font-family: $font__museo--300;
  color: $color_dark-grey;
}

p {
  margin-bottom: 20px;
}

img {
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming)*/
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}

#app {
  background: rgb(71, 205, 199);
  background: -moz-linear-gradient(222deg, rgba(71, 205, 199, 1) 0%, rgba(255, 145, 163, 1) 100%);
  background: -webkit-linear-gradient(222deg, rgba(71, 205, 199, 1) 0%, rgba(255, 145, 163, 1) 100%);
  background: linear-gradient(222deg, rgba(71, 205, 199, 1) 0%, rgba(255, 145, 163, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#47cdc7",endColorstr="#ff91a3",GradientType=1);
}

// base styling mainly for WYSIWYG content
.wysiwyg {
  video {
    width: 100%;
    height: auto;
  }
  a {
    color: $color_blue;
  }
  img {
    max-width: 100%;
    width: auto;
  }
  h1 {
    font-family: $font__museo--1000;
    margin-bottom: 20px;
    * {
      font-family: $font__museo--1000 !important;
    }
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font__museo--700;
    margin-bottom: 20px;

    * {
      font-family: $font__museo--700 !important;
    }
  }
  strong {
    font-family: $font__museo--700;
    * {
      font-family: $font__museo--700 !important;
    }
  }
  em {
    font-style: italic;
  }

  h1 {
    @include font-size(30);
  }
  h2 {
    @include font-size(22);
  }
  h3 {
    @include font-size(18);
  }
  h4 {
    @include font-size(15);
  }
  h5 {
    @include font-size(13);
  }
  h6 {
    @include font-size(11);
  }
}

// Simplebar
// .simplebar-scrollbar {
//   background: rgb(71, 205, 199);
//   background: -moz-linear-gradient(222deg, rgba(71, 205, 199, 1) 0%, rgba(255, 145, 163, 1) 100%);
//   background: -webkit-linear-gradient(222deg, rgba(71, 205, 199, 1) 0%, rgba(255, 145, 163, 1) 100%);
//   background: linear-gradient(222deg, rgba(71, 205, 199, 1) 0%, rgba(255, 145, 163, 1) 100%);
//   border-radius: 5px;
//   &:before {
//     display: none;
//   }
// }
//
// .simplebar-track {
//   background-color: rgba(0, 0, 0, 0.2);
//   border-radius: 5px;
//   &.simplebar-vertical {
//     width: 6px !important;
//     right: 5px;
//   }
// }
