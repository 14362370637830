.lazy-load__placeholder {
  background-position: center;
  opacity: 0.4;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.3);
  background-size: 20%;
  @include backgroundimage('/base/logo.png');

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.2) 100%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}
