.modal-layout__client {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  padding: {
    top: 40px;
    bottom: 40px;
  }
}
