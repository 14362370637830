/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../../scss/abstracts/breakpoints';
@import '../../../../scss/abstracts/colors';
@import '../../../../scss/abstracts/mixins';
@import '../../../../scss/abstracts/typography';
@import '../../../../scss/abstracts/animation';
@import '../../../../scss/abstracts/variables';

.home-header__showreel {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: $screen-md-min) {
    width: 50%;
    justify-content: flex-end;
  }

  @media only screen and (min-width: $screen-lg-min) {
    width: 30%;
  }

  a {
    border: 2px solid #fff;
    border-radius: 25px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 25px;
    text-decoration: none;
    position: relative;
    overflow: hidden;

    @include transition(border-color, color);

    @media (hover: hover) {
      &:hover {
        border-color: $color_yellow;
        color: $color_yellow;

        .svg-icon--viemo {
          fill: $color_yellow;
        }
      }
    }
  }
}

.home-header__showreel__link-content {
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: $font__museo--900;
}

.home-header__showreel .svg-icon--viemo {
  margin-right: 15px;

  @include transition(fill);
}
