.contact-address {
  color: #fff;
  background: rgb(71, 205, 199);
  background: -moz-linear-gradient(90deg, rgba(71, 205, 199, 1) 0%, rgba(71, 167, 206, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(71, 205, 199, 1) 0%, rgba(71, 167, 206, 1) 100%);
  background: linear-gradient(90deg, rgba(71, 205, 199, 1) 0%, rgba(71, 167, 206, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#47cdc7",endColorstr="#47a7ce",GradientType=1);

  @include font-size(14);
  font-family: $font__museo--300;

  h2 {
    font-family: $font__museo--700;
    margin-bottom: 10px;

    @include font-size(26);

    @media only screen and (min-width: $screen-md-min) {
      @include font-size(24);
    }
  }

  margin-bottom: -120px;
}

.contact-address__title {
  color: $color_yellow;
  font-family: $font__museo--1000;
  @include font-size(30);
  text-align: center;

  @media only screen and (min-width: 600px) {
    margin-bottom: 20px;
  }

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(45);

    padding: {
      right: 50px;
      left: 50px;
    }
  }

  @media only screen and (min-width: $screen-lg-min) {
    padding: {
      right: 100px;
      left: 100px;
    }
  }
}

.contact-address__description {
  color: #fff;
  padding-bottom: 60px;
  font-family: $font__museo--500;
  @include font-size(15);

  @media only screen and (min-width: $screen-md-min) {
    padding: {
      right: 100px;
      left: 100px;
    }
  }

  @media only screen and (min-width: $screen-lg-min) {
    padding: {
      right: 240px;
      left: 240px;
    }
  }
}

.contact-address .bg-container {
  //padding: 40px 0;
  background-position: top center;
  background-repeat: no-repeat;
  //background-size: auto 70%;
  background-size: cover;

  @media only screen and (min-width: 600px) {
    //background-size: auto 80%;
    background-position: center 50%;
    //background-size: 130%;
  }

  @media only screen and (min-width: $screen-md-min) {
    //padding: 40px 0 80px;
  }

  @media only screen and (min-width: $screen-lg-min) {
    //background-size: 100%;
  }
}

.contact-address .container.container-rotate {
  background-color: #fff;

  transform: rotate(0);

  @media only screen and (min-width: $screen-md-min) {
    transform: rotate(-1deg);
  }

  .container-unrotate {
    transform: rotate(0);

    @media only screen and (min-width: $screen-md-min) {
      transform: rotate(1deg);
    }
  }
}

.contact-address .container-title {
  padding-top: 60px;
  padding-bottom: 60px;
}

.contact-address .container-address {
  display: flex;
  flex-wrap: wrap;
  padding-top: 60px;
  max-width: 80%;
  margin-left: 10%;
  @media only screen and (max-width: 600px) {
    position: relative;
    padding-top: 20px;
  }
}

.contact-address__center {
  position: relative;
  width: 100%;

  @media only screen and (min-width: 600px) {
    width: 50%;
    text-align: left;
  }

  img {
    position: relative;
    display: block;
    width: 80%;
    max-width: 340px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 40px;
    @media only screen and (min-width: 600px) {
      position: absolute;
      top: -100px;
    }
  }
}
.contact-address__left,
.contact-address__right {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;

  //@media only screen and (min-width: 400px) {
  //  margin-bottom: 0;
  //
  //  width: 50%;
  //}

  @media only screen and (min-width: 600px) {
    width: 25%;
    text-align: left;
  }
}

.contact-address__right {
  color: $color_dark-grey;
  @media only screen and (min-width: 400px) {
    margin-left: auto;
  }
  @media only screen and (min-width: 600px) {
    width: 25%;
    text-align: left;
  }
}

.contact-address__address {
  margin-bottom: 20px;
  color: $color_dark-grey;
  p {
    margin-bottom: 15px;

    @include font-size(20);

    @media only screen and (min-width: 600px) {
      @include font-size(16);
    }
  }
}

.contact-address__google-map {
  display: flex;
  justify-content: center;

  @media only screen and (min-width: 600px) {
    justify-content: flex-start;
  }

  a {
    border: 2px solid $color_cyan;
    border-radius: 25px;
    color: $color_cyan;
    display: inline;
    padding: 5px 10px;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    font-family: $font__museo--700;
    @include font-size(20);

    @media only screen and (min-width: 600px) {
      @include font-size(16);
    }

    @include transition(border-color, color);

    @media (hover: hover) {
      &:hover {
        border-color: $color_yellow;
        color: $color_yellow;

        .svg-icon--viemo {
          fill: $color_yellow;
        }
      }
    }
  }
}
.contact-address__tel,
.contact-address__email {
  margin-bottom: 20px;

  a {
    color: $color_dark-grey;
    text-decoration: none;
    @include font-size(20);

    @media only screen and (min-width: 600px) {
      @include font-size(16);
    }
  }
}

.contact-address__social .social-icons__list--column {
  display: flex;
  flex-direction: column;
  text-align: left;

  @media only screen and (max-width: 600px) {
    justify-content: center;
    align-items: center;
  }
}

.contact-address__social .social-icons__list--column .social-icons__link {
  margin: {
    top: 0;
    right: 0;
    bottom: 5px;
    left: 0;
  }

  @media only screen and (min-width: 600px) {
    display: flex;
    justify-content: flex-start;
  }

  a {
    display: flex;
    align-items: center;
    color: $color_dark-grey;
    text-decoration: none;
    @include font-size(20);

    @media only screen and (min-width: 600px) {
      @include font-size(16);
    }
  }
}

.contact-address__social .social-icons__list--column .social-icons__icon {
  margin-right: 10px;

  svg {
    width: 18px;
    fill: $color_dark-grey;
  }
}

.contact-address .container-form {
  padding-top: 40px;
  max-width: 100%;
  margin: auto;

  @media only screen and (min-width: $screen-md-min) {
    max-width: 80%;
  }

  @media only screen and (min-width: $screen-lg-min) {
    max-width: 60%;
  }
}
