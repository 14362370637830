.page-not-found {
  background-color: #fff;
  padding-top: 40px;
  padding-bottom: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
}
