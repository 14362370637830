.page-not-found h1 {
  text-align: center;
  color: $color_yellow;
  font-family: $font__museo--1000;
  margin-bottom: 20px;
  @include font-size(35);

  @media only screen and (min-width: $screen-md-min) {
    margin-bottom: 40px;
    @include font-size(60);
  }
}

.page-not-found h2 {
  margin-bottom: 20px;

  @include font-size(26);

  @media only screen and (min-width: $screen-md-min) {
    margin-bottom: 40px;

    @include font-size(30);
  }
}

.page-not-found__back-button {
  background-color: transparent;
  border: 2px solid $color_dark-grey;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin: {
    top: 0px;
    right: 15px;
    bottom: 10px;
    left: 15px;
  }

  @media only screen and (min-width: $screen-md-min) {
    margin-right: 0;
    margin-left: 0;
  }

  @include transition(border-color);

  a {
    color: $color_dark-grey;
    font-family: $font__museo--900;
    text-decoration: none;
    @include font-size(14);
    @include transition(color);
  }

  &:hover {
    border-color: $color_yellow;

    a {
      color: $color_yellow;
    }
  }
}
