@use 'sass:math';

/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math

//Import base abstracts files
@import '../../../scss/abstracts/breakpoints';
@import '../../../scss/abstracts/colors';
@import '../../../scss/abstracts/mixins';
@import '../../../scss/abstracts/typography';
@import '../../../scss/abstracts/animation';
@import '../../../scss/abstracts/variables';

.clients__testimonials {
  background: rgb(71, 205, 199);
  background: -moz-linear-gradient(222deg, rgba(71, 167, 206, 1) 0%, rgba(71, 205, 199, 1) 100%);
  background: -webkit-linear-gradient(222deg, rgba(71, 167, 206, 1) 0%, rgba(71, 205, 199, 1) 100%);
  background: linear-gradient(222deg, rgba(71, 167, 206, 1) 0%, rgba(71, 205, 199, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#47cdc7",endColorstr="#ff91a3",GradientType=1);

  padding: 40px 0;
  overflow: hidden;

  @media only screen and (min-width: $screen-md-min) {
    padding: 80px 0;
  }

  .container {
    position: relative;

    @media only screen and (max-width: $screen-md-min) {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.clients__testimonials .clients__testimonials_title {
  position: relative;

  text-align: center;
  color: #fff;
  font-family: $font__museo__1000;
  margin-bottom: 40px;
  @include font-size(45);

  @media only screen and (min-width: $screen-md-min) {
    @include font-size(60);
  }

  @media only screen and (min-width: $screen-lg-min) {
    @include font-size(95);
  }
}

.clients__testimonials_grid-masonry {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &--left,
  &--right {
    width: 100%;

    @media only screen and (min-width: $screen-md-min) {
      width: 50%;
    }
  }

  &--container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    max-width: 375px;
    margin: auto;

    @media only screen and (min-width: $screen-md-min) {
      max-width: 890px;
    }
  }
}

.clients__testimonials_grid-masonry-brick {
  flex-direction: column;
  position: relative;
  padding: 0 15px;
  width: 100%;
}

.masonry-brick {
  position: relative;
  border: 4px solid #f08ea3;
  border-bottom: 0;
  border-radius: 50px 50px 0 0;
  padding: 50px;
  padding-bottom: 0;
  margin-bottom: 35%;

  transform: rotate(2deg);

  // @media only screen and (min-width: 550px) and (max-width: $screen-md-min) {
  //   border-radius: 80px 80px 0 0;
  // }

  @media only screen and (min-width: 375px) {
    border-radius: 60px 60px 0 0;

    border-width: 5px;
  }

  @media only screen and (min-width: $screen-md-min) and (max-width: $screen-lg-min) {
    border-width: 4px;
  }

  @media only screen and (min-width: $screen-lg-min) {
    border-radius: 80px 80px 0 0;
  }

  &:after {
    content: '';
    top: 99.5%;
    content: '';
    height: 0;
    left: -4px;
    padding-top: 33%;
    position: absolute;
    width: calc(100% + 8px);
    background-size: 100%;

    @media only screen and (min-width: 375px) {
      left: -5px;
      width: calc(100% + 10px);
    }

    @media only screen and (min-width: $screen-md-min) and (max-width: $screen-lg-min) {
      left: -4px;
      width: calc(100% + 8.25px);
    }

    @media only screen and (min-width: $screen-lg-min) {
      left: -5px;
      width: calc(100% + 10.5px);
    }
  }
}

// tile sizes
// original
// small  : 360, 314
// medium : 401, 337
// marge  : 513, 450
$sml-ratio: math.div(314, 360);
$sml-w: 310px;
$sml-h: $sml-w * $sml-ratio;
$med-ratio: math.div(337, 401);
$med-w: 350px;
$med-h: $med-w * $med-ratio;
$lge-ratio: math.div(450, 513);
$lge-w: 450px;
$lge-h: $lge-w * $lge-ratio;

.masonry-brick-left {
  margin-left: auto;
  margin-right: 0;
}

.masonry-brick-right {
  margin-left: 0;
  margin-right: auto;
}

.clients__testimonial_block__details {
  transform: rotate(-2deg);

  z-index: 2;
  width: 100%;
  color: $color_white;
  text-align: left;
  line-height: 1.4;
}

.clients__testimonial_block_quote {
  font-family: $font__museo--300;
  margin-bottom: 3px;
  @include font-size(12);

  @media only screen and (min-width: 376px) {
    @include font-size(13);
    margin-bottom: 6px;
  }

  @media only screen and (min-width: 550px) {
    @include font-size(20);
  }
}

.clients__testimonial_block_info {
  font-family: $font__museo--900;
  @include font-size(10);
  margin-bottom: 3px;

  @media only screen and (min-width: 376px) {
    @include font-size(12);
    margin-bottom: 6px;
  }

  @media only screen and (min-width: 550px) {
    @include font-size(18);
  }
}

.clients__testimonial_block_company {
  font-family: $font__museo--900;
  @include font-size(10);

  @media only screen and (min-width: 376px) {
    @include font-size(12);
  }

  @media only screen and (min-width: 550px) {
    @include font-size(14);
  }
}
