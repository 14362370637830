.modal--overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.8);
  z-index: 9999;
  overflow: hidden;

  .container {
    position: relative;
    height: 100%;
  }
}

.modal__close {
  background-color: transparent;
  border: none;
  height: 50px;
  position: absolute;
  width: 50px;
  padding: 0;
  right: 30px;
  top: 20px;
  cursor: pointer;

  .svg-icon--close {
    @include transition(fill);

    &:hover {
      fill: $color_yellow;
    }
  }
}

// cookie policy
.modal--cookie-policy {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
  max-width: 400px;
  padding: 15px;

  &__container {
    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.75);
    padding: 15px;
  }

  &--hidden {
    opacity: 0;
    display: none;
  }

  p.title {
    font-family: $font__museo--700;
  }

  a,
  button {
    font-family: $font__museo--700;
    color: $color_white;
  }

  .modal--cookie-policy__button {
    display: flex;

    button,
    a {
      text-align: center;
      text-decoration: none;
      width: 50%;
      margin: 5px;
      border: none;
      border-radius: 20px;
      padding: 10px;
      background-color: $color_dark-grey;
      cursor: pointer;
    }
  }
}
