/*
This file is used to contain all abstracts imports.
*/
// https://sass-lang.com/documentation/modules/math
@use 'sass:math';

//Import base abstracts files
@import '../../../../scss/abstracts/breakpoints';
@import '../../../../scss/abstracts/colors';
@import '../../../../scss/abstracts/mixins';
@import '../../../../scss/abstracts/typography';
@import '../../../../scss/abstracts/animation';
@import '../../../../scss/abstracts/variables';

.home-header__awards {
  display: none;
  list-style: none;

  @media only screen and (min-width: $screen-md-min) {
    width: 50%;
    display: flex;
  }

  @media only screen and (min-width: $screen-lg-min) {
    width: 70%;
  }
}

.home-header__awards__logo {
  margin-right: 13%;

  &:last-child {
    margin-right: 0;
  }

  img {
    height: 40px;
    max-height: 40px;
    width: auto;
  }
}
