.category__grid {
  .social-content__block {
    flex-basis: 50%;
    padding-top: 33%;

    @media only screen and (min-width: $screen-sm-min) {
      flex-basis: 33.3%;
      padding-top: 23%;
    }

    @media only screen and (min-width: $screen-md-min) {
      flex-basis: 25%;
      padding-top: 19%;
    }
  }

  &--3 {
    .social-content__block {
      flex-basis: 100%;
      padding-top: 60%;

      @media only screen and (min-width: $screen-sm-min) {
        flex-basis: 33.3%;
        padding-top: 23%;
      }
    }
  }
}

.social-content__block {
  display: flex;
  // justify-content: center;
  align-items: center;
  position: relative;

  transform-origin: center;
  @include transition(transform 0.5s);
}

.social-content__block__container--last-row .social-content__block__desc {
  bottom: 100%;
  top: auto;
  display: flex;
  align-items: flex-end;
  padding: 0px 15px 15px;
}

.social-content__block__arrow-icon {
  position: absolute;
  z-index: 20;
  bottom: 15px;
  left: 15px;

  .btn {
    background: none;
    border: none;
    color: #444;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    outline: none;
    transition: all 0.2s cubic-bezier(0, 0, 0.3, 1);

    outline: none;
  }
  .btn__icon {
    width: 20px;
    height: 20px;
    fill: #444;
    transition: all 0.25s cubic-bezier(0, 0, 0.3, 1);
  }

  .btn__icon--top {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.social-content__block__desc {
  position: absolute;
  top: 100%;
  height: auto;
  width: 100%;
  left: 0;
  color: #fff;
  opacity: 0;
  padding: 15px 15px 0;

  @include transition(opacity 0.5s);
}

.social-content__block__background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  opacity: 1;
  @include transition(transform, opacity);
  z-index: 10;
}

.social-content__block--show {
  transform: scale(1);
}

.social-content__block__video {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.social-content__block__inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;

  &--loading {
    background-color: rgba(0, 0, 0, 0.3);

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -150px;
      top: 0;
      height: 100%;
      width: 150px;
      background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.1) 100%, transparent 100%);
      animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
  }
}

.social-content__block__container--active {
  z-index: 1001;

  &.has-video {
    .social-content__block__background-image,
    video {
      opacity: 0;
    }
  }

  &:not(.has-video) {
    .social-content__block__arrow-icon {
      opacity: 0.1;
    }
    .social-content__block__background-image {
      opacity: 0.1;
    }
  }
  &.has-image {
    .social-content__block__arrow-icon {
      opacity: 1;
    }
    .social-content__block__background-image {
      opacity: 1;
    }
  }
  .social-content__block__desc {
    opacity: 1;
    z-index: 100;
  }
  .btn--flip-out {
    .btn__icon--bottom {
      transform: translateY(-7.75%);
    }

    .btn__icon--top {
      transform: rotateX(-180deg) translateY(-7px);
    }
  }
}

.social-content__overlay {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1002;
  padding: 20px 0;

  .container {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: $screen-md-min) {
      flex-direction: row;
    }
  }

  .swiper-container {
    height: 100%;
  }
}

.social-content__overlay__desc-box {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 50px 10px 0;

  min-width: 200px;
}

.social-content__overlay__close {
  position: absolute;
  top: -10px;
  left: -10px;
  background: transparent;
  border: none;
  cursor: pointer;

  svg {
    width: 50px;
  }
}

.social-content__overlay__title {
  color: #fff;
  font-family: $font__museo--700;
  margin-bottom: 20px;
}

.social-content__overlay__desc {
  color: #fff;
  font-family: $font__museo--300;
  @include font-size(14);
}

.social-content__overlay__slides {
  padding: 0 10px;
  display: block;
  flex-grow: 2;
}

.social-content__overlay__swiper-controls {
  margin-left: -20px;
  margin-top: auto;

  button {
    background: transparent;
    border: none;

    width: 60px;
    padding: 0;
    margin: 0;
    @include transition(opacity);
    cursor: pointer;

    &[disabled] {
      cursor: auto;
      opacity: 0.1;
    }
  }
}
.swiper-slide {
  align-self: flex-start;
  &.social-content__slideshow__video-slide {
    width: 100%;
    height: 100%;

    @media only screen and (min-width: $screen-md-min) {
      width: 40%;
    }
  }
  &.social-content__slideshow__video-other {
    width: 100%;
    height: 100%;

    @media only screen and (min-width: $screen-md-min) {
      width: auto;
    }
  }
}

.social-content__slideshow__name {
  color: #fff;
  font-family: $font__museo--300;
  @include font-size(12);
  margin-top: 10px;
}
